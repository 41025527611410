import DrawerLoading from '../components/DrawerLoading.vue';

const drawersData = {
  village: markRaw(defineAsyncComponent({ loader: () => import('./VillageDetailDrawer/VillageDetailDrawer.vue'), loadingComponent: DrawerLoading })),
  land: markRaw(defineAsyncComponent({ loader: () => import('./LandDetailDrawer/LandDetailDrawer.vue'), loadingComponent: DrawerLoading })),
  person: markRaw(defineAsyncComponent({ loader: () => import('./PersonDrawer/PersonDrawer.vue'), loadingComponent: DrawerLoading })),
  contractor: markRaw(defineAsyncComponent({ loader: () => import('./ContractorDrawer/ContractorDrawer.vue'), loadingComponent: DrawerLoading })),
} as const;

export default drawersData;
