import { AsyncComponentLoader, Component } from 'vue';
import { infoNotification } from '@/helpers';

const importList: Record<string, AsyncComponentLoader<Component>> = import.meta.glob('../pages/**/*.vue');

//@todo Понять как возвращать нормальные данные
export const loadImport = (path: string) => {
  return new Promise(async (resolve) => {
    const globalStore = useGlobalStore();

    globalStore.topProgressStart();

    try {
      const component = await importList[path]();

      resolve(component);
    } catch (e) {
      console.error(e);
      infoNotification(`Для корректной работы приложения необходимо обновить страницу. <br><br> Используйте <span class="text-bold">CTRL+SHIFT+R</span>`, 'Доступна новая версия', true);
    } finally {
      globalStore.topProgressFinish();
    }
  });
};
