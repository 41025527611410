import { http } from '@/http';
import { LandPersonDto, LandPersonRole, LandCardDto, LandOwnershipDto, LandPersonWithDetailsDto } from '@/http/api';
import { LandPersonRoleEnum } from '@/models';
import { useEventBus } from '@vueuse/core';
import { fullNameStringParsing, successNotification, errorNotification, landNumberStringParsing } from '@/helpers';
import { useDialog } from '../useDialog';

type LandOwnershipEvents = 'on-changed';

type UseLandOwnershipsParams = {
  onChange?: (...args: any[]) => any;
};

const landOwnershipsBus = useEventBus<LandOwnershipEvents>('land-ownerships');

export type UseLandOwnershipsReturnValue = ReturnType<typeof useLandOwnerships>;

export function useLandOwnerships({ onChange }: UseLandOwnershipsParams = { onChange: null }) {
  const router = useRouter();
  const globalStore = useGlobalStore();

  const events = {
    'on-changed': onChange,
  };

  const eventListenter = (e) => events[e]?.();

  if (onChange instanceof Function) {
    landOwnershipsBus.on(eventListenter);
  }

  async function onOpenSetPersonRoleDialog(role: LandPersonRole, person: LandPersonWithDetailsDto) {
    let dialogParams = null;

    if (role === LandPersonRoleEnum.Owner.value) {
      dialogParams = {
        title: 'Назначение лица собственником',
        content: 'Данному лицу будет присвоена роль собственника. Это позволит установить ему долю и настроить данные документов.',
        confrimTitle: 'Сделать собственником',
      };
    } else if (role === LandPersonRoleEnum.Representative.value) {
      dialogParams = {
        title: 'Назначение лица представителем',
        content: 'Данному лицу будет присвоена роль представителя. Это позволит настраивать рассылку по собственности для данного лица.',
        confrimTitle: 'Сделать представителем',
      };
    } else if (role === LandPersonRoleEnum.Visitor.value) {
      if (person.role === LandPersonRoleEnum.Owner.value) {
        dialogParams = {
          title: 'Удаление из собственников',
          content: 'Лицу будет возвращена роль “Посетитель” в собственности данного участка.',
          confrimTitle: 'Убрать из собственников',
        };
      } else if (person.role === LandPersonRoleEnum.Representative.value) {
        dialogParams = {
          title: 'Удаление из представителей',
          content: 'Лицу будет возвращена роль “Посетитель” в собственности данного участка.',
          confrimTitle: 'Убрать из представителей',
        };
      }
    }

    useDialog(
      dialogParams.content,

      {
        title: dialogParams.title,
        confirmButtonConfig: {
          title: dialogParams.confrimTitle,
        },
        confirm: () => setLandPersonRole(role, person),
      }
    );
  }

  async function setLandPersonRole(role: string, person: LandPersonWithDetailsDto) {
    try {
      if (role === LandPersonRoleEnum.Owner.value) {
        await http.landPerson.makeOwnerCreate(person.id);

        successNotification(
          `Лицо <span class="text-bold">${fullNameStringParsing(person.personIdentity)}</span> успешно назначен(а) собственником для данной собственности.`,
          'Собственник добавлен',
          true
        );
      } else if (role === LandPersonRoleEnum.Representative.value) {
        await http.landPerson.makeRepresentativeCreate(person.id);

        successNotification(
          `Лицо <span class="text-bold">${fullNameStringParsing(person.personIdentity)}</span> успешно назначен(а) представителем для данной собственности.`,
          'Представитель добавлен',
          true
        );
      } else if (role === LandPersonRoleEnum.Visitor.value) {
        await http.landPerson.makeVisitorCreate(person.id);

        if (person.role === LandPersonRoleEnum.Owner.value) {
          successNotification(
            `Лицо <span class="text-bold">${fullNameStringParsing(person.personIdentity)}</span> успешно удалён из списка собственников для данной собственности.`,
            'Собственник удалён',
            true
          );
        } else if (person.role === LandPersonRoleEnum.Representative.value) {
          successNotification(
            `Лицо <span class="text-bold">${fullNameStringParsing(person.personIdentity)}</span> успешно удалён из списка представителей для данной собственности.`,
            'Представитель удалён',
            true
          );
        }
      }

      landOwnershipsBus.emit('on-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось изменить роль лица');
    }
  }

  const checkLandOwnershipActivationData = reactive({
    canBeActivated: false,
    alreadyActivated: false,
    isDeactivated: false,
    notAnyOwners: false,
    wrongOwnerShares: false,
    wrongLandOwnershipType: false,
  });

  async function checkLandOwnershipActivation(landOwnership: LandOwnershipDto) {
    try {
      const { data } = await http.landOwnership.checkActivationCreate(landOwnership.id);

      Object.assign(checkLandOwnershipActivationData, data);
    } catch (error) {
      errorNotification('Не удалось загрузить собсвтенность участка');

      throw error;
    }
  }

  async function onOpenActivateLandOwnershipDialog(landOwnership: LandOwnershipDto, land: LandCardDto) {
    console.log(landOwnership);

    useDialog(
      'Вы собираетесь активировать собственность данного участка. После активации список собственников будет невозможно изменить.',

      {
        title: 'Активация собственности',
        confirmButtonConfig: {
          title: 'Активировать собственность',
        },
        confirm: () => activateLandOwnership(landOwnership, land),
      }
    );
  }

  async function activateLandOwnership(landOwnership: LandOwnershipDto, land: LandCardDto) {
    try {
      await http.landOwnership.activateCreate(landOwnership.id);

      successNotification(
        `Собственность <span class="text-bold">№${landOwnership.index}</span> для участка <span class="text-bold">${landNumberStringParsing(toValue(land).name)} ${
          toValue(land).villageName
        }</span> является активной.`,
        'Собственность активирована',
        true
      );

      landOwnershipsBus.emit('on-changed');
    } catch (error) {
      errorNotification('Не удалось активировать собсвтенность участка');
      throw error;
    }
  }

  const checkLandOwnershipDeactivationData = reactive({
    canBeDeactivated: false,
    notActivated: false,
    alreadyDeactivated: false,
    hasAnyActiveContracts: false,
  });

  async function checkLandOwnershipDeactivation(landOwnership: LandOwnershipDto) {
    try {
      const { data } = await http.landOwnership.checkDeactivationCreate(landOwnership.id);

      Object.assign(checkLandOwnershipDeactivationData, data);
    } catch (error) {
      errorNotification('Не удалось загрузить собсвтенность участка');

      throw error;
    }
  }

  async function onOpenDeactivateLandOwnershipDialog(landOwnership: LandOwnershipDto, land: LandCardDto) {
    console.log(landOwnership);

    useDialog(
      (await import('@/components/LandOwnerships/DeactivateLandOwneshipDialog.vue')).default,

      {
        title: 'Закрытие собственности',
        hideFooter: true,
        confirm: () => deactivateLandOwnership(landOwnership, land),
      },
      {}
    );
  }

  async function deactivateLandOwnership(landOwnership: LandOwnershipDto, land: LandCardDto) {
    try {
      await http.landOwnership.deactivateCreate(landOwnership.id);

      successNotification(
        `Собственность <span class="text-bold">№${landOwnership.index}</span> для участка <span class="text-bold">${landNumberStringParsing(toValue(land).name)} ${
          toValue(land).villageName
        }</span> является закрытой.`,
        'Собственность деактивирована',
        true
      );

      landOwnershipsBus.emit('on-changed');
    } catch (error) {
      errorNotification('Не удалось деактивировать собсвтенность участка');
      throw error;
    }
  }

  const checkMailingSettingsData = reactive({
    hasMailingIntegration: true,
    hasFinanceModule: true,
    landPersons: [],
  });

  async function checkMailingSettings(landOwnership: LandOwnershipDto) {
    try {
      const { data } = await http.landOwnership.checkMailingDetail(toValue(landOwnership).id);

      Object.assign(checkMailingSettingsData, data);
    } catch (error) {
      errorNotification('Попробуйте позже', 'Не удалось проверить настройки рассылки');

      throw error;
    }
  }

  async function onOpenMailingSettingsDialog(landOwnership: LandOwnershipDto, personsList: LandPersonDto[]) {
    console.log(landOwnership);

    useDialog(
      (await import('@/components/LandOwnerships/MailingSettingsDialog.vue')).default,

      {
        title: 'Настройки рассылки',
        hideFooter: true,
        height: '80vh',
        confirm: (landPersons) => setMailingSettings(landOwnership, landPersons),
      },
      {
        mailingSettings: checkMailingSettingsData,
        landPersons: personsList,
      }
    );
  }

  async function setMailingSettings(landOwnership: LandOwnershipDto, payload) {
    try {
      await http.landOwnership.setMailingSettingsCreate(landOwnership.id, payload);

      successNotification(`Настройки рассылки успешно сохранены`);

      landOwnershipsBus.emit('on-changed');
    } catch (error) {
      errorNotification('Не удалось настроить рассылку');
    }
  }

  async function onOpenRedirectCreatePersonDialog() {
    if (!globalStore.userLocalSettings.messageRedirectToCreatePersonForm) {
      useDialog(
        (await import('@/components/LandOwnerships/RedirectToCreatePersonDialog.vue')).default,

        {
          title: 'Добавление лица',
          hideFooter: true,
          confirm: () => window.open(router.resolve({ name: 'persons' }).href, '_blank'),
        },
        {}
      );
    } else {
      window.open(router.resolve({ name: 'persons' }).href, '_blank');
    }
  }

  return {
    onOpenSetPersonRoleDialog,
    onOpenActivateLandOwnershipDialog,
    onOpenDeactivateLandOwnershipDialog,
    onOpenMailingSettingsDialog,
    onOpenRedirectCreatePersonDialog,

    checkLandOwnershipActivationData,
    checkLandOwnershipActivation,

    checkLandOwnershipDeactivationData,
    checkLandOwnershipDeactivation,

    checkMailingSettingsData,
    checkMailingSettings,
  };
}
