import { useEventBus } from '@vueuse/core';
import { GetEntityComposableTypes } from './helpers';

type Events = ['lokeo:account-created', 'lokeo:login-changed', 'lokeo:invite-send', 'lokeo-data:account-created', 'lokeo-data:login-changed', 'lokeo-data:invite-send'];

type ComposableTypes = GetEntityComposableTypes<Events>;

export const accountsEventBus = useEventBus<ComposableTypes['EventsUnion']>('accounts');

export function useAccountsEventBus(params?: ComposableTypes['Params']) {
  const events: ComposableTypes['EventsHandlers'] = {
    'lokeo:account-created': params?.onLokeoAccountCreated,
    'lokeo:invite-send': params?.onLokeoInviteSend,
    'lokeo:login-changed': params?.onLokeoLoginChanged,
    'lokeo-data:account-created': params?.onLokeoAccountCreated,
    'lokeo-data:invite-send': params?.onLokeoInviteSend,
    'lokeo-data:login-changed': params?.onLokeoLoginChanged,
  };

  const eventListener = (e: keyof ComposableTypes['EventsHandlers']) => events[e]?.();
  accountsEventBus.on(eventListener);
  onBeforeUnmount(() => accountsEventBus.off(eventListener));
}
