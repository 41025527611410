import { useEventBus } from '@vueuse/core';
import { GetEntityComposableTypes } from './helpers';

type Events = ['created', 'updated', 'deleted', 'archived', 'restored'];

type ComposableTypes = GetEntityComposableTypes<Events>;

export const contractorsEventBus = useEventBus<ComposableTypes['EventsUnion']>('contractors');

export function useContractorsEventBus(params?: ComposableTypes['Params']) {
  const events: ComposableTypes['EventsHandlers'] = {
    created: params?.onCreated,
    updated: params?.onUpdated,
    archived: params?.onArchived,
    deleted: params?.onDeleted,
    restored: params?.onRestored,
  };

  const eventListener = (e: keyof typeof events) => events[e]?.();
  contractorsEventBus.on(eventListener);
  onBeforeUnmount(() => contractorsEventBus.off(eventListener));
}
