<template>
  <button
    ref="appButtonRef"
    class="app-button"
    :class="[`--${type}`, { '--icon-left': iconLeft, '--icon-right': iconRight, '--icon-only': !slots.default }]"
    @click="$emit('click', $event)">
    <AppIcon
      v-if="iconLeft"
      class="app-button__left-icon"
      :name="iconLeft"
      :size="type === 'link' ? '16px' : '20px'"
      :rotate="iconLeftRotate" />

    <slot />

    <AppIcon
      v-if="iconRight"
      class="app-button__right-icon"
      :name="iconRight"
      :size="type === 'link' ? '16px' : '20px'"
      :rotate="iconRightRotate" />
  </button>
</template>

<script lang="ts" setup>
interface Props {
  type: 'primary' | 'outline' | 'text' | 'alert' | 'link';
  iconLeft?: GlobalIconsNames;
  iconRight?: GlobalIconsNames;
  iconLeftRotate?: string;
  iconRightRotate?: string;
}

interface Emits {
  (e: 'click', event: MouseEvent);
}

withDefaults(defineProps<Props>(), {
  iconLeft: null,
  iconRight: null,
  iconLeftRotate: '0deg',
  iconRightRotate: '0deg',
});

defineEmits<Emits>();

const slots = useSlots();

const appButtonRef = ref(null);

defineExpose({ appButtonRef });
</script>

<style lang="scss" scoped>
.app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 32px;
  border-radius: 12px;
  font-family: inherit;

  flex-shrink: 0;
  outline: none;
  border: none;
  transition: transition(background-color, color);

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  @include text-B1;

  &.--icon-right {
    padding-right: 24px;
  }

  &.--icon-left {
    padding-left: 24px;
  }

  &.--icon-only {
    padding: 10px;
  }

  &.--link {
    height: max-content;
    width: max-content;
    background-color: transparent;
    padding: 0;
    color: var(--primary-700);
    gap: 2px;

    &:hover:not(:disabled) {
      color: var(--primary-400);
    }

    &:active:not(:disabled) {
      color: var(--neutral-800);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--primary {
    background: var(--primary-600);
    color: var(--neutral-100);

    &:hover:not(:disabled) {
      background: var(--primary-400);
    }

    &:active:not(:disabled) {
      background: var(--primary-700);
    }

    &:disabled {
      background-color: var(--primary-500);
      opacity: 0.3;
    }
  }

  &.--outline {
    background: var(--neutral-300);
    color: var(--neutral-800);

    &:hover:not(:disabled) {
      background: var(--primary-50);
    }

    &:active:not(:disabled) {
      background: var(--primary-100);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--text {
    background: transparent;
    color: var(--neutral-800);

    &:hover:not(:disabled) {
      background: var(--primary-50);
    }

    &:active:not(:disabled) {
      background: var(--primary-100);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--alert {
    background: var(--neutral-300);
    color: var(--alert-500);

    &:hover:not(:disabled) {
      background: var(--alert-100);
    }

    &:active:not(:disabled) {
      background: var(--alert-300);
      color: var(--alert-700);
    }

    &:disabled {
      .app-icon {
        opacity: 0.3;
      }
    }
  }
}
</style>
