export const useNestIndex = (function () {
  let indexesStack = [];

  return {
    lastValue: () => indexesStack.at(-1),
    add(value: number) {
      indexesStack.push(value);
    },
    remove() {
      indexesStack.splice(indexesStack.length - 1, 1);
    },
  };
})();
