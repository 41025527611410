import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const landOwnershipsRoutes = [
  {
    path: 'edit-land-ownership-document/:landOwnershipId',
    name: 'edit-land-ownership-document',
    component: () => loadImport('../pages/PageEditLandOwnershipDocument/PageEditLandOwnershipDocument.vue'),
    meta: {
      requiresAuth: true,
      isPageDetail: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
