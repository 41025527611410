import { useEventBus } from '@vueuse/core';
import { GetEntityComposableTypes } from './helpers';

type Events = ['deleted', 'archived', 'restored', 'attached-to-land', 'detached-from-land', 'attached-to-contractor', 'detached-from-contractor', 'created', 'updated'];

type ComposableTypes = GetEntityComposableTypes<Events>;

export const personsEventBus = useEventBus<ComposableTypes['EventsUnion']>('persons');

export function usePersonsEventBus(params?: ComposableTypes['Params']) {
  const events: ComposableTypes['EventsHandlers'] = {
    deleted: params?.onDeleted,
    archived: params?.onArchived,
    restored: params?.onRestored,
    created: params?.onCreated,
    updated: params?.onUpdated,
    'attached-to-land': params?.onDetachedFromLand,
    'detached-from-land': params?.onDetachedFromLand,
    'attached-to-contractor': params?.onAttachedToContractor,
    'detached-from-contractor': params?.onDetachedFromContractor,
  };

  const eventListener = (e: keyof ComposableTypes['EventsHandlers']) => events[e]?.();
  personsEventBus.on(eventListener);
  onBeforeUnmount(() => personsEventBus.off(eventListener));
}
