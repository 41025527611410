import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routes from './routes';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as unknown as RouteRecordRaw[],
});

async function checkVersion(href: string) {
  if (import.meta.env.DEV) return;

  const globalStore = useGlobalStore();
  const version = await globalStore.getVersion();

  if (version.BundleVersion !== globalStore.appBundleVersion) {
    console.log('new version has been detected, reload');

    globalStore.appVersion = version.Version;
    globalStore.appBundleVersion = version.BundleVersion;

    if (href) {
      window.location.href = href;
    } else {
      window.location.reload();
    }
  }
}

router.beforeEach(async (to, from, next) => {
  await checkVersion(router.resolve(to).href);

  const isAuthorized = !!localStorage.getItem('access-token');
  const toSomeAuthPage = to.matched.some((route) => (route.name as string).includes('auth'));

  if (isAuthorized && toSomeAuthPage) {
    return next({ name: 'start' });
  } else if (!isAuthorized && !toSomeAuthPage) {
    if (to.matched.some((route) => route.meta.isPublicPage)) {
      return next();
    }

    return next({ name: 'auth-login' });
  }

  next();
});
