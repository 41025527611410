import {
  ContractorPersonRole,
  Sex,
  IdentityDocumentType,
  LandPersonRole,
  AllowedUseType,
  LandCategory,
  LandOwnershipStatus,
  ContactType,
  LandOwnershipType,
  LandOwnershipCommonType,
  LandOwnershipDocumentType,
  LandPersonEmotionalStatus,
  ContractorLegalType,
} from '@/http/api';

type AppEnumPreview<T extends string> = {
  [Key in T]: {
    label: string;
    value: Key;
  };
};

export const LandPersonRoleEnum: AppEnumPreview<LandPersonRole> = {
  [LandPersonRole.Owner]: {
    label: 'Собственник',
    value: LandPersonRole.Owner,
  },

  [LandPersonRole.Representative]: {
    label: 'Представитель',
    value: LandPersonRole.Representative,
  },

  [LandPersonRole.Visitor]: {
    label: 'Посетитель',
    value: LandPersonRole.Visitor,
  },
};

export const LandsAllowedUseTypeEnum: AppEnumPreview<AllowedUseType> = {
  [AllowedUseType.UseType1]: {
    label: 'ИЖС',
    value: AllowedUseType.UseType1,
  },

  [AllowedUseType.UseType2]: {
    label: 'ЛПХ',
    value: AllowedUseType.UseType2,
  },

  [AllowedUseType.UseType3]: {
    label: 'КФХ',
    value: AllowedUseType.UseType3,
  },

  [AllowedUseType.UseType4]: {
    label: 'Сельхозназначения',
    value: AllowedUseType.UseType4,
  },

  [AllowedUseType.UseType5]: {
    label: 'Для садоводства',
    value: AllowedUseType.UseType5,
  },
};

export const LandsCategoryTypeEnum: AppEnumPreview<LandCategory> = {
  [LandCategory.LandCategory1]: {
    label: 'Земли сельскохозяйственного назначения',
    value: LandCategory.LandCategory1,
  },

  [LandCategory.LandCategory2]: {
    label: 'Земли населенных пунктов',
    value: LandCategory.LandCategory2,
  },

  [LandCategory.LandCategory3]: {
    label: 'Земли промышленности',
    value: LandCategory.LandCategory3,
  },
};

export const LandOwnershipStatusEnum: AppEnumPreview<LandOwnershipStatus> = {
  [LandOwnershipStatus.Created]: {
    label: 'Неактивна',
    value: LandOwnershipStatus.Created,
  },

  [LandOwnershipStatus.Activated]: {
    label: 'Активна',
    value: LandOwnershipStatus.Activated,
  },

  [LandOwnershipStatus.Deactivated]: {
    label: 'Закрыта',
    value: LandOwnershipStatus.Deactivated,
  },
};

export const ContactTypeEnum: AppEnumPreview<ContactType> = {
  [ContactType.Email]: {
    label: 'E-mail',
    value: ContactType.Email,
  },

  [ContactType.PhoneNumber]: {
    label: 'Номер телефона',
    value: ContactType.PhoneNumber,
  },
};

export const LandOwnershipDocumentTypeEnum: AppEnumPreview<LandOwnershipDocumentType> = {
  [LandOwnershipDocumentType.DocumentType1]: {
    label: 'Договор купли-продажи',
    value: LandOwnershipDocumentType.DocumentType1,
  },

  [LandOwnershipDocumentType.DocumentType2]: {
    label: 'Договор аренды',
    value: LandOwnershipDocumentType.DocumentType2,
  },

  [LandOwnershipDocumentType.DocumentType3]: {
    label: 'Свидетельство о праве на наследство',
    value: LandOwnershipDocumentType.DocumentType3,
  },

  [LandOwnershipDocumentType.DocumentType4]: {
    label: 'Договор дарения',
    value: LandOwnershipDocumentType.DocumentType4,
  },

  [LandOwnershipDocumentType.DocumentType5]: {
    label: 'Судебный приказ',
    value: LandOwnershipDocumentType.DocumentType5,
  },
};

export const LandOwnershipTypeEnum: AppEnumPreview<LandOwnershipType> = {
  [LandOwnershipType.NotInitialized]: {
    label: 'Неинициализированный',
    value: LandOwnershipType.NotInitialized,
  },

  [LandOwnershipType.Private]: {
    label: 'Индивидуальная',
    value: LandOwnershipType.Private,
  },

  [LandOwnershipType.Common]: {
    label: 'Общая',
    value: LandOwnershipType.Common,
  },
};

export const LandOwnershipCommonTypeEnum: AppEnumPreview<LandOwnershipCommonType> = {
  [LandOwnershipCommonType.NotInitialized]: {
    label: 'Неинициализированный',
    value: LandOwnershipCommonType.NotInitialized,
  },

  [LandOwnershipCommonType.Shares]: {
    label: 'Долевая',
    value: LandOwnershipCommonType.Shares,
  },

  [LandOwnershipCommonType.Joint]: {
    label: 'Совместная',
    value: LandOwnershipCommonType.Joint,
  },
};

export const ContractorPersonRoleEnum: AppEnumPreview<ContractorPersonRole> = {
  [ContractorPersonRole.Client]: {
    label: 'Клиент',
    value: ContractorPersonRole.Client,
  },

  [ContractorPersonRole.Employee]: {
    label: 'Сотрудник',
    value: ContractorPersonRole.Employee,
  },

  [ContractorPersonRole.Owner]: {
    label: 'Собственник',
    value: ContractorPersonRole.Owner,
  },
};

export const SexEnum: AppEnumPreview<Sex> = {
  [Sex.Male]: { label: 'Мужской', value: Sex.Male },
  [Sex.Female]: { label: 'Женский', value: Sex.Female },
};

export const IdentityDocumentTypeEnum: AppEnumPreview<IdentityDocumentType> = {
  [IdentityDocumentType.NoDocument]: {
    label: 'Нет документа',
    value: IdentityDocumentType.NoDocument,
  },
  [IdentityDocumentType.ForeignPassport]: {
    label: 'Паспорт иностранного гражданина',
    value: IdentityDocumentType.ForeignPassport,
  },
  [IdentityDocumentType.RussianBirthCertificate]: {
    label: 'Свидетельство о рождении',
    value: IdentityDocumentType.RussianBirthCertificate,
  },
  [IdentityDocumentType.RussianPassport]: {
    label: 'Паспорт гражданина РФ',
    value: IdentityDocumentType.RussianPassport,
  },
  [IdentityDocumentType.RussianResidencePermit]: {
    label: 'Вид на жительство в РФ',
    value: IdentityDocumentType.RussianResidencePermit,
  },
};

export const LandPersonEmotionalStatusEnum: AppEnumPreview<LandPersonEmotionalStatus> = {
  [LandPersonEmotionalStatus.Negative]: {
    label: 'Отрицательный',
    value: LandPersonEmotionalStatus.Negative,
  },
  [LandPersonEmotionalStatus.Neutral]: {
    label: 'Нейтральный',
    value: LandPersonEmotionalStatus.Neutral,
  },
  [LandPersonEmotionalStatus.Positive]: {
    label: 'Положительный',
    value: LandPersonEmotionalStatus.Positive,
  },
};

export const ContractorLegalTypeEnum: AppEnumPreview<ContractorLegalType> = {
  [ContractorLegalType.Individual]: {
    label: 'Физическое лицо',
    value: ContractorLegalType.Individual,
  },
  [ContractorLegalType.LegalEntity]: {
    label: 'Юридическое лицо',
    value: ContractorLegalType.LegalEntity,
  },
  [ContractorLegalType.SelfEmployed]: {
    label: 'Самозанятый',
    value: ContractorLegalType.SelfEmployed,
  },
  [ContractorLegalType.SoleProprietor]: {
    label: 'ИП',
    value: ContractorLegalType.SoleProprietor,
  },
};
