<template>
  <div
    class="sidebar"
    :class="{ 'sidebar--compact': sidebarStore.isCompact && displayType === SidebarDisplayTypeModel.DEFAULT }">
    <MainSidebar v-if="displayType === SidebarDisplayTypeModel.DEFAULT" />
    <DetailSidebar v-else />
  </div>
</template>

<script lang="ts" setup>
import { SidebarDisplayTypeModel } from './helpers';

import MainSidebar from './components/MainSidebar';
import DetailSidebar from './components/DetailSidebar';

type Props = {
  displayType: (typeof SidebarDisplayTypeModel)[keyof typeof SidebarDisplayTypeModel];
};

defineProps<Props>();

const sidebarStore = useSidebarStore();
</script>

<style lang="scss" scoped>
.sidebar {
  width: 224px;
  transition: transition(width);

  &--compact {
    width: 64px;
  }
}

:deep() {
  .sidebar__header {
    display: grid;
    grid-template-columns: 114px 24px;
    align-items: center;
    justify-content: space-between;

    padding: 32px 16px 0 20px;
  }

  .sidebar__header_logo-text {
    width: 68px;
    height: 34px;
    opacity: 1;
    transition: transition(opacity);
    mask-image: url('/logo/logo-text-full-ru.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('/logo/logo-text-full-ru.svg');
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;

    background-color: white;
  }

  .sidebar__menu-item_soon-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--primary-700);
    border-radius: 6px;
    width: 8px;
    height: 8px;
    color: var(--primary-700);
    text-transform: uppercase;
    font-size: 10px;
    line-height: 18px;
    font-weight: 500;
    z-index: 1000;
    transition: transition(color, width, height);
  }
}
</style>
