import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const landRoutes = [
  {
    path: 'land-cadastre/:landId',
    name: 'edit-land-cadastre',
    component: () => loadImport('../pages/PageEditLandCadastre/PageEditLandCadastre.vue'),
    meta: {
      requiresAuth: true,
      isPageDetail: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
