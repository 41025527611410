export function errorNotification(message: string | number, title: string | number = '') {
  if (typeof message !== 'string' && typeof message !== 'number') {
    console.warn('Функция errorNotification может принимать сообщение только формата string или number');
    return;
  }

  if (typeof title !== 'string' && typeof title !== 'number') {
    console.warn('Функция errorNotification может принимать заголовок только формата string или number');
    return;
  }

  ElNotification({
    title: String(title),
    message: String(message),
    type: 'error',
    customClass: 'ui-notification--error',
    position: 'bottom-right',
    duration: 15000,
  });
}

export function successNotification(message: string | number, title: string | number = '', useHtml = false) {
  if (typeof message !== 'string' && typeof message !== 'number') {
    console.warn('Функция successNotification может принимать сообщение только формата string или number');
    return;
  }
  if (typeof title !== 'string' && typeof title !== 'number') {
    console.warn('Функция successNotification может принимать заголовок только формата string или number');
    return;
  }
  ElNotification({
    title: String(title),
    message: String(message),
    type: 'success',
    customClass: 'ui-notification--success',
    dangerouslyUseHTMLString: useHtml,
    position: 'bottom-right',
    duration: 15000,
  });
}

export function infoNotification(message: string | number, title: string | number = '', useHtml = false) {
  if (typeof message !== 'string' && typeof message !== 'number') {
    console.warn('Функция infoNotification может принимать сообщение только формата string или number');
    return;
  }
  if (typeof title !== 'string' && typeof title !== 'number') {
    console.warn('Функция infoNotification может принимать заголовок только формата string или number');
    return;
  }
  ElNotification({
    title: String(title),
    message: String(message),
    type: 'info',
    customClass: 'ui-notification--info',
    dangerouslyUseHTMLString: useHtml,
    position: 'bottom-right',
    duration: 15000,
  });
}

export function warnNotification(message: string | number, title: string | number = '') {
  if (typeof message !== 'string' && typeof message !== 'number') {
    console.warn('Функция warnNotification может принимать сообщение только формата string или number');
    return;
  }
  if (typeof title !== 'string' && typeof title !== 'number') {
    console.warn('Функция warnNotification может принимать заголовок только формата string или number');
    return;
  }

  ElNotification({
    title: String(title),
    message: String(message),
    type: 'warning',
    customClass: 'ui-notification--warning',
    position: 'bottom-right',
    duration: 15000,
  });
}
