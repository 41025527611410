<template>
  <div
    v-if="!source"
    class="app-icon" />

  <img
    v-else
    :src="'/' + name + '.svg'"
    :width="sizeForSource"
    :height="sizeForSource"
    :style="{ transform: transformBind }" />
</template>

<script lang="ts" setup>
interface Props {
  name: GlobalIconsNames;
  source?: boolean;
  color?: string;
  size?: string;
  rotate?: string;
}
const props = withDefaults(defineProps<Props>(), {
  source: false,
  color: 'currentColor',
  size: '16px',
  rotate: '0deg',
});

const maskBind = computed(() => {
  return `url('/${props.name}.svg') no-repeat center`;
});

const transformBind = computed(() => `rotate(${props.rotate})`);

const sizeForSource = computed(() => {
  return props.size.replace('px', '');
});
</script>

<style lang="scss" scoped>
.app-icon {
  mask: v-bind(maskBind);
  -webkit-mask: v-bind(maskBind);
  mask-size: contain;
  -webkit-mask-size: contain;
  width: v-bind(size);
  height: v-bind(size);
  background-color: v-bind(color);
  flex-shrink: 0;
  transform: v-bind(transformBind);
  transition: transition(transform);
}
</style>
