/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Вид разрешенного использования (ВРИ):   *UseType1 - ИЖС   *UseType2 - ЛПХ   *UseType3 - КФХ   *UseType4 - Сельхозназначения   *UseType5 - Для садоводства */
export enum AllowedUseType {
  UseType1 = "UseType1",
  UseType2 = "UseType2",
  UseType3 = "UseType3",
  UseType4 = "UseType4",
  UseType5 = "UseType5",
}

export interface ChangeLoginForMobileLokeoAccountChangeLoginForLokeoAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Новый логин (телефон)
   * @minLength 1
   */
  login: string;
}

export interface ChangeLoginForWebLokeoAccountChangeLoginForLokeoDataAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Новый логин (email)
   * @minLength 1
   */
  login: string;
}

export interface ChangeVillageNameUpdateVillageDto {
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
}

export interface CheckLandIntervalCheckLandIntervalDto {
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Начало диапазона
   * @format int32
   */
  startNumber: number;
  /**
   * Конец диапазона
   * @format int32
   */
  endNumber: number;
  /**
   * Optional: цифра префикса
   * @format int32
   */
  prefixDigit?: number | null;
  /** Optional: буква префикса */
  prefixLetter?: string | null;
  /**
   * Optional: цифра постфикса
   * @format int32
   */
  postfixDigit?: number | null;
  /** Optional: буква постфикса */
  postfixLetter?: string | null;
}

export interface CheckLandOwnershipActivationCheckActivationDto {
  /** @format uuid */
  id: string;
  /** Возможна ли активация */
  canBeActivated: boolean;
  /** Собственность уже активирована */
  alreadyActivated: boolean;
  /** Нельзя активировать деактивированную собственность */
  isDeactivated: boolean;
  /** Нет собственников */
  notAnyOwners: boolean;
  /** Неправильно настроены доли собственников */
  wrongOwnerShares: boolean;
  /** Неправильно указан тип собственности */
  wrongLandOwnershipType: boolean;
}

export interface CheckLandOwnershipDeactivationCheckDeactivationDto {
  /** @format uuid */
  id: string;
  /** Можно ли деактивировать собственность */
  canBeDeactivated: boolean;
  /** Собственность ещё не активирована */
  notActivated: boolean;
  /** Уже деактивирована */
  alreadyDeactivated: boolean;
  /** Есть активные лицевые счета */
  hasAnyActiveContracts: boolean;
}

export interface CheckLandOwnershipMailingDto {
  /** @format uuid */
  id: string;
  /** Подключена интеграция с сервисом рассылки */
  hasMailingIntegration: boolean;
  /** Подключён финансовый модуль */
  hasFinanceModule: boolean;
  /** Собственники и представители */
  landPersons: CheckLandOwnershipMailingDtoLandPersonDto[];
}

export interface CheckLandOwnershipMailingDtoLandPersonDto {
  /** @format uuid */
  id: string;
  /** Отсутствует email для рассылки */
  noMailingEmail: boolean;
  /** Есть более одного участка в собственности */
  moreThanLandOwnership: boolean;
}

/** Тип контакта:   *PhoneNumber - Телефон   *Email - Почта */
export enum ContactType {
  PhoneNumber = "PhoneNumber",
  Email = "Email",
}

export interface ContractorBankRequisitesDto {
  /**
   * Id банковского счёт.
   * Если null - то создать новый счёт.
   * Если не null - то отредактировать.
   * @format uuid
   */
  id?: string | null;
  /** Является основным счётом */
  isMain: boolean;
  /**
   * Наименование банка
   * @minLength 1
   */
  bankName: string;
  /**
   * БИК банка
   * @minLength 1
   */
  bankBik: string;
  /**
   * Номер расчетного счета
   * @minLength 1
   */
  checkingAccount: string;
  /**
   * Номер корреспондентского счета
   * @minLength 1
   */
  correspondentAccount: string;
}

export interface ContractorCanBeDeletedDto {
  /** @format uuid */
  id: string;
  /** Можно ли удалить контрагента */
  canBeDeleted: boolean;
  /** Причина невозможности: к контрагенту добавлены лица */
  hasAnyPersons: boolean;
  /** Причина невозможности: контрагент был добавлен к лицевым счетам */
  hasAnyContracts: boolean;
}

export interface ContractorCanBeEditedDto {
  /** @format uuid */
  id: string;
  classification?: ContractorCanBeEditedDtoClassificationCanBeEditedDto;
  bankRequisites?: ContractorCanBeEditedDtoContractorBankRequisitesCanBeEditedDto[] | null;
}

export interface ContractorCanBeEditedDtoClassificationCanBeEditedDto {
  /** Можно ли редактировать классификацию (ИНН, ОГРН, ОГРНИП) */
  canBeEdited: boolean;
  /** Причина невозможности: контрагенту был выставлен счёт */
  hasAnyBills: boolean;
  /** Причина невозможности: контрагент был добавлен к лицевым счетам */
  hasAnyContracts: boolean;
}

export interface ContractorCanBeEditedDtoContractorBankRequisitesCanBeEditedDto {
  /** @format uuid */
  id: string;
  /** Можно ли редактировать банковские реквизиты */
  canBeEdited: boolean;
  /** Причина невозможности: была проведена выписка с использованием данных реквизитов */
  hasAnyBankStatements: boolean;
  /** Причина невозможности: контрагенту был выставлен счёт по данным реквизитам */
  hasAnyBills: boolean;
  /** Причина невозможности: контрагент был добавлен к лицевым счетам с данными реквизитами */
  hasAnyContracts: boolean;
}

export interface ContractorCardDto {
  /** @format uuid */
  id: string;
  /** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
  legalType: ContractorLegalType;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Полное наименование контрагента
   * @minLength 1
   */
  fullName: string;
  /**
   * Адрес контрагента
   * @minLength 1
   */
  address: string;
  /** Список банковских реквизитов */
  bankRequisitesCollection: ContractorBankRequisitesDto[];
  management?: ContractorManagementDto;
  classification: ContractorClassificationDto;
  contact: ContractorContactDto;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface ContractorClassificationDto {
  /**
   * Классификация контрагента
   * @minLength 1
   */
  inn: string;
  legalEntityClassification?: ContractorLegalEntityClassificationDto;
  soleProprietorClassification?: ContractorSoleProprietorClassificationDto;
}

export interface ContractorContactDto {
  /** Номер телефона */
  phoneNumber?: string | null;
  /** Электронная почта */
  email?: string | null;
}

export interface ContractorDto {
  /** @format uuid */
  id: string;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Полное наименование контрагента
   * @minLength 1
   */
  fullName: string;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface ContractorDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface ContractorDtoPagedResultDto {
  metaData?: ContractorDtoPagedMetadata;
  items?: ContractorDto[] | null;
}

export interface ContractorLegalEntityClassificationDto {
  /**
   * КПП
   * @minLength 1
   */
  kpp: string;
  /**
   * ОГРН
   * @minLength 1
   */
  ogrn: string;
}

/** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
export enum ContractorLegalType {
  LegalEntity = "LegalEntity",
  SoleProprietor = "SoleProprietor",
  Individual = "Individual",
  SelfEmployed = "SelfEmployed",
}

export interface ContractorManagementDto {
  /**
   * Имя генерального директора
   * @minLength 1
   */
  directorName: string;
  /** Имя главного бухгалтера */
  chiefAccountantName?: string | null;
  /** Имя кассира */
  cashierName?: string | null;
}

export interface ContractorPersonDto {
  /** @format uuid */
  id: string;
  /**
   * Должность лица
   * @minLength 1
   */
  position: string;
  /** ::   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник:   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник */
  role: ContractorPersonRole;
  /**
   * Id контрагента
   * @format uuid
   */
  contractorId: string;
}

export interface ContractorPersonListInPersonCardDto {
  /** @format uuid */
  id: string;
  /** ::   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник:   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник */
  role: ContractorPersonRole;
  contractor: ContractorPersonListInPersonCardDtoContractorDto;
}

export interface ContractorPersonListInPersonCardDtoContractorDto {
  /** @format uuid */
  id: string;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
}

export interface ContractorPersonListInPersonCardDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface ContractorPersonListInPersonCardDtoPagedResultDto {
  metaData?: ContractorPersonListInPersonCardDtoPagedMetadata;
  items?: ContractorPersonListInPersonCardDto[] | null;
}

/** ::   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник:   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник */
export enum ContractorPersonRole {
  Employee = "Employee",
  Client = "Client",
  Owner = "Owner",
}

export interface ContractorPersonWithDetailsDto {
  /** @format uuid */
  id: string;
  /**
   * Должность лица
   * @minLength 1
   */
  position: string;
  /** ::   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник:   *Employee - Сотрудник   *Client - Клиент   *Owner - Собственник */
  role: ContractorPersonRole;
  personIdentity: ContractorPersonWithDetailsDtoPersonIdentityDto;
}

export interface ContractorPersonWithDetailsDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface ContractorPersonWithDetailsDtoPagedResultDto {
  metaData?: ContractorPersonWithDetailsDtoPagedMetadata;
  items?: ContractorPersonWithDetailsDto[] | null;
}

export interface ContractorPersonWithDetailsDtoPersonIdentityDto {
  /** @format uuid */
  id: string;
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /**
   * Отчетство
   * @minLength 1
   */
  middleName: string;
  /**
   * Фамилия
   * @minLength 1
   */
  lastName: string;
  /** Контакты лица */
  personContacts: PersonContactDto[];
}

export interface ContractorSoleProprietorClassificationDto {
  /**
   * Огрнип
   * @minLength 1
   */
  ogrnip: string;
}

export interface ContractorToAttachDto {
  /** @format uuid */
  id: string;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /** Добавлено ли лицо к этому контрагенту */
  isAttached: boolean;
}

export interface ContractorToAttachDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface ContractorToAttachDtoPagedResultDto {
  metaData?: ContractorToAttachDtoPagedMetadata;
  items?: ContractorToAttachDto[] | null;
}

export interface ContractorWithDetailsDto {
  /** @format uuid */
  id: string;
  /** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
  legalType: ContractorLegalType;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Количество участков
   * @format int32
   */
  personCount: number;
  classification: ContractorWithDetailsDtoContractorClassificationDto;
  bankRequisites: ContractorWithDetailsDtoContractorBankRequisitesDto;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface ContractorWithDetailsDtoContractorBankRequisitesDto {
  /** @format uuid */
  id: string;
  /** Номер расчетного счета */
  checkingAccount?: string | null;
}

export interface ContractorWithDetailsDtoContractorClassificationDto {
  /** ИНН контрагента */
  inn?: string | null;
}

export interface ContractorWithDetailsDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface ContractorWithDetailsDtoPagedResultDto {
  metaData?: ContractorWithDetailsDtoPagedMetadata;
  items?: ContractorWithDetailsDto[] | null;
}

export interface CreateContractorCommandCreateContractor {
  /** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
  legalType: ContractorLegalType;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Полное наименование контрагента
   * @minLength 1
   */
  fullName: string;
  /**
   * Адрес контрагента
   * @minLength 1
   */
  address: string;
  /** Список банковских реквизитов */
  bankRequisitesCollection: ContractorBankRequisitesDto[];
  management?: ContractorManagementDto;
  classification: ContractorClassificationDto;
  contact: ContractorContactDto;
}

export interface CreateContractorPersonAttachPersonToContractorDto {
  /**
   * PersonId
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Id контрагента
   * @format uuid
   */
  contractorId: string;
  /**
   * Должность лица
   * @minLength 1
   */
  position: string;
}

export interface CreateIdentityLegacyCreateIdentityLegacyDto {
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phoneNumber: string;
  /** @minLength 1 */
  password: string;
}

export interface CreateLandCreateLandDto {
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Номер участка
   * @format int32
   */
  number: number;
  /**
   * Optional: Цифра префикса
   * @format int32
   */
  prefixDigit?: number | null;
  /** Optional: Буква префикса */
  prefixLetter?: string | null;
  /**
   * Optional: Цифра постфикса
   * @format int32
   */
  postfixDigit?: number | null;
  /** Optional: Буква постфикса */
  postfixLetter?: string | null;
}

export interface CreateLandPersonAttachPersonToLandDto {
  /** @format uuid */
  personIdentityId: string;
  /** @format uuid */
  landOwnershipId: string;
}

export interface CreateManyLandsCreateManyLandsDto {
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Начало диапазона
   * @format int32
   */
  startNumber: number;
  /**
   * Конец диапазона
   * @format int32
   */
  endNumber: number;
  /**
   * Optional: цифра префикса
   * @format int32
   */
  prefixDigit?: number | null;
  /** Optional: буква префикса */
  prefixLetter?: string | null;
  /**
   * Optional: цифра постфикса
   * @format int32
   */
  postfixDigit?: number | null;
  /** Optional: буква постфикса */
  postfixLetter?: string | null;
}

export interface CreateMobileLokeoAccountCreateLokeoAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Номер телефона
   * @minLength 1
   */
  phoneNumber: string;
}

export interface CreatePersonIdentityCreatePersonDto {
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /** Отчество */
  middleName?: string | null;
  /** Фамилия */
  lastName?: string | null;
  /** Контрагент */
  counterparty: PersonCounterpartyDto;
  /** Контакты лица */
  personContacts: PersonContactDto[];
}

export interface CreateVillageCreateVillageDto {
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
}

export interface CreateWebLokeoAccountCreateLokeoDataAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Электронная почта
   * @minLength 1
   */
  email: string;
}

export interface DadataContactorResponseDtoDadataContractorClassificationDto {
  /**
   * Классификация контрагента
   * @minLength 1
   */
  inn: string;
  legalEntityClassification?: ContractorLegalEntityClassificationDto;
  soleProprietorClassification?: ContractorSoleProprietorClassificationDto;
}

export interface DadataContactorResponseDtoDadataContractorDto {
  /** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
  legalType: ContractorLegalType;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Полное наименование контрагента
   * @minLength 1
   */
  fullName: string;
  /**
   * Адрес контрагента
   * @minLength 1
   */
  address: string;
  management: DadataContactorResponseDtoDadataContractorManagementDto;
  classification: DadataContactorResponseDtoDadataContractorClassificationDto;
}

export interface DadataContactorResponseDtoDadataContractorManagementDto {
  /**
   * Имя генерального директора
   * @minLength 1
   */
  directorName: string;
}

/** ::: */
export enum EntityProgressStatus {
  Created = "Created",
  Updated = "Updated",
  Archived = "Archived",
  Restored = "Restored",
  Deleted = "Deleted",
}

export interface EntityResultDto {
  /** @format uuid */
  id?: string;
  /** ::: */
  status?: EntityProgressStatus;
}

export interface FractionShareDto {
  /** Пустое значение дроби */
  isEmpty: boolean;
  /**
   * Числитель дроби
   * @format int32
   */
  numenator: number;
  /**
   * Знаменатель дроби
   * @format int32
   */
  denominator: number;
}

export interface GetContractorFromDadataDadataContactorResponseDto {
  isFound?: boolean;
  suggestions?: DadataContactorResponseDtoDadataContractorDto[] | null;
}

/** Тип документа:   *NoDocument - Документ отсутствует   *RussianPassport - Паспорт РФ   *ForeignPassport - Иностранный паспорт   *RussianResidencePermit - ВНЖ РФ   *RussianBirthCertificate - Свидетельство о рождении РФ */
export enum IdentityDocumentType {
  NoDocument = "NoDocument",
  RussianPassport = "RussianPassport",
  ForeignPassport = "ForeignPassport",
  RussianResidencePermit = "RussianResidencePermit",
  RussianBirthCertificate = "RussianBirthCertificate",
}

export interface LandCanBeDeletedDto {
  /** @format uuid */
  id: string;
  /** Можно ли удалить участок */
  canBeDeleted: boolean;
  /** Причина невозможности: к участку добавлены лица */
  hasAnyPersons: boolean;
  /** Причина невозможности: к участку добавлены лицевые счета */
  hasAnyContracts: boolean;
}

export interface LandCardDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  villageName: string;
  /**
   * Величина долга
   * @format double
   */
  debt: number;
  /**
   * Последняя видимая собственность (либо начальная созданная, либо активированная, либо закрытая)
   * @format uuid
   */
  lastVisibleLandOwnershipId?: string | null;
  cadastreData: LandCardDtoCadastreDto;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface LandCardDtoCadastreDto {
  /** Созданы ли кадастровые данные */
  isEmpty: boolean;
  /** Optional: Кадастровый номер */
  cadastreNumber?: string | null;
  /**
   * Optional: Площадь участка
   * @format double
   */
  area?: number | null;
  /** Optional: Адрес */
  address?: string | null;
  /** Вид разрешенного использования (ВРИ):   *UseType1 - ИЖС   *UseType2 - ЛПХ   *UseType3 - КФХ   *UseType4 - Сельхозназначения   *UseType5 - Для садоводства */
  allowedUseType?: AllowedUseType;
  /**
   * Optional: Стоимость участка
   * @format double
   */
  cost?: number | null;
  /** Категория земель:   *LandCategory1 - Земли сельскохозяйственного назначения   *LandCategory2 - Земли населенных пунктов   *LandCategory3 - Земли промышленности */
  landCategory?: LandCategory;
}

/** Категория земель:   *LandCategory1 - Земли сельскохозяйственного назначения   *LandCategory2 - Земли населенных пунктов   *LandCategory3 - Земли промышленности */
export enum LandCategory {
  LandCategory1 = "LandCategory1",
  LandCategory2 = "LandCategory2",
  LandCategory3 = "LandCategory3",
}

export interface LandDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Название посёлка
   * @minLength 1
   */
  villageName: string;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface LandDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandDtoPagedResultDto {
  metaData?: LandDtoPagedMetadata;
  items?: LandDto[] | null;
}

export interface LandNameDto {
  /**
   * Optional: Номер участка
   * @format int32
   */
  number?: number | null;
  /**
   * Optional: Цифра префикса
   * @format int32
   */
  prefixDigit?: number | null;
  /** Optional: Буква префикса */
  prefixLetter?: string | null;
  /**
   * Optional: Цифра постфикса
   * @format int32
   */
  postfixDigit?: number | null;
  /** Optional: Буква постфикса */
  postfixLetter?: string | null;
}

/** Типы общей собственности:   *NotInitialized - Неинициализированный тип   *Shares - Долевая   *Joint - Совместная */
export enum LandOwnershipCommonType {
  NotInitialized = "NotInitialized",
  Shares = "Shares",
  Joint = "Joint",
}

/** Тип документа собственности:   *DocumentType1 - Договор купли-продажи   *DocumentType2 - Договор аренды   *DocumentType3 - Свидетельство о праве на наследство   *DocumentType4 - Договор дарения   *DocumentType5 - Судебный приказ */
export enum LandOwnershipDocumentType {
  DocumentType1 = "DocumentType1",
  DocumentType2 = "DocumentType2",
  DocumentType3 = "DocumentType3",
  DocumentType4 = "DocumentType4",
  DocumentType5 = "DocumentType5",
}

export interface LandOwnershipDto {
  /** @format uuid */
  id: string;
  /**
   * Индекс собственности (начинается с 1)
   * @format int32
   */
  index: number;
  /** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
  status: LandOwnershipStatus;
}

export interface LandOwnershipDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandOwnershipDtoPagedResultDto {
  metaData?: LandOwnershipDtoPagedMetadata;
  items?: LandOwnershipDto[] | null;
}

/** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
export enum LandOwnershipStatus {
  Created = "Created",
  Activated = "Activated",
  Deactivated = "Deactivated",
}

/** Тип владения собственностью:   *NotInitialized - Неинициализированный тип   *Private - Индивидуальная   *Common - Общая */
export enum LandOwnershipType {
  NotInitialized = "NotInitialized",
  Private = "Private",
  Common = "Common",
}

export interface LandOwnershipWithDetailsDto {
  /** @format uuid */
  id: string;
  /** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
  status: LandOwnershipStatus;
  /** Тип владения собственностью:   *NotInitialized - Неинициализированный тип   *Private - Индивидуальная   *Common - Общая */
  type: LandOwnershipType;
  /** Типы общей собственности:   *NotInitialized - Неинициализированный тип   *Shares - Долевая   *Joint - Совместная */
  commonType: LandOwnershipCommonType;
  /**
   * Индекс собственности (начинается с 1)
   * @format int32
   */
  index: number;
  settings: LandOwnershipWithDetailsDtoLandOwnershipSettingsDto;
  mailingSettings: LandOwnershipWithDetailsDtoLandOwnershipMailingSettingsDto;
}

export interface LandOwnershipWithDetailsDtoLandOwnershipCadastreDto {
  /** Номер регистрации права */
  registrationNumber?: string | null;
  /**
   * Дата гос.регистрации права
   * @format date
   */
  registrationDate?: string | null;
}

export interface LandOwnershipWithDetailsDtoLandOwnershipDocumentDto {
  /** Тип документа собственности:   *DocumentType1 - Договор купли-продажи   *DocumentType2 - Договор аренды   *DocumentType3 - Свидетельство о праве на наследство   *DocumentType4 - Договор дарения   *DocumentType5 - Судебный приказ */
  documentType: LandOwnershipDocumentType;
  /**
   * Номер документа
   * @minLength 1
   */
  documentNumber: string;
  /**
   * Дата документа
   * @format date
   */
  documentDate: string;
}

export interface LandOwnershipWithDetailsDtoLandOwnershipMailingSettingsDto {
  /** Настройки созданы */
  isCreated: boolean;
}

export interface LandOwnershipWithDetailsDtoLandOwnershipSettingsDto {
  /** Настройки созданы */
  isCreated: boolean;
  document?: LandOwnershipWithDetailsDtoLandOwnershipDocumentDto;
  cadastre?: LandOwnershipWithDetailsDtoLandOwnershipCadastreDto;
}

export interface LandPersonDto {
  /** @format uuid */
  id: string;
  /** Роль лица:   *Visitor - Посетитель   *Representative - Представитель   *Owner - Собственник */
  role: LandPersonRole;
  share: FractionShareDto;
  /** Рассылка: отчёт по собственности */
  notifyWithOwnershipReport: boolean;
  /** Рассылка: созданные начисления */
  notifyAboutProductAccruals: boolean;
}

/** Эмоциональный статус лица:   *Neutral - Нейтральный   *Positive - Положительный   *Negative - Отрицательный */
export enum LandPersonEmotionalStatus {
  Neutral = "Neutral",
  Positive = "Positive",
  Negative = "Negative",
}

export interface LandPersonListInPersonCardDto {
  /** @format uuid */
  id: string;
  /** Роль лица:   *Visitor - Посетитель   *Representative - Представитель   *Owner - Собственник */
  role: LandPersonRole;
  share: FractionShareDto;
  /**
   * Долг
   * @format double
   */
  debt: number;
  /** Рассылка: отчёт по собственности */
  notifyWithOwnershipReport: boolean;
  /** Рассылка: созданные начисления */
  notifyAboutProductAccruals: boolean;
  landOwnership: LandPersonListInPersonCardDtoLandOwnershipDto;
  land: LandPersonListInPersonCardDtoLandDto;
  village: LandPersonListInPersonCardDtoVillageDto;
}

export interface LandPersonListInPersonCardDtoLandDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
}

export interface LandPersonListInPersonCardDtoLandOwnershipDto {
  /** @format uuid */
  id: string;
  /**
   * Индекс собственности (начинается с 1)
   * @format int32
   */
  index: number;
  /** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
  status: LandOwnershipStatus;
  /** Тип владения собственностью:   *NotInitialized - Неинициализированный тип   *Private - Индивидуальная   *Common - Общая */
  type: LandOwnershipType;
  /** Типы общей собственности:   *NotInitialized - Неинициализированный тип   *Shares - Долевая   *Joint - Совместная */
  commonType: LandOwnershipCommonType;
}

export interface LandPersonListInPersonCardDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandPersonListInPersonCardDtoPagedResultDto {
  metaData?: LandPersonListInPersonCardDtoPagedMetadata;
  items?: LandPersonListInPersonCardDto[] | null;
}

export interface LandPersonListInPersonCardDtoVillageDto {
  /** @format uuid */
  id: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
}

/** Роль лица:   *Visitor - Посетитель   *Representative - Представитель   *Owner - Собственник */
export enum LandPersonRole {
  Visitor = "Visitor",
  Representative = "Representative",
  Owner = "Owner",
}

export interface LandPersonWithDetailsDto {
  /** @format uuid */
  id: string;
  /** Роль лица:   *Visitor - Посетитель   *Representative - Представитель   *Owner - Собственник */
  role: LandPersonRole;
  share: FractionShareDto;
  /** Рассылка: отчёт по собственности */
  notifyWithOwnershipReport: boolean;
  /** Рассылка: созданные начисления */
  notifyAboutProductAccruals: boolean;
  personIdentity: LandPersonWithDetailsDtoPersonIdentityDto;
}

export interface LandPersonWithDetailsDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandPersonWithDetailsDtoPagedResultDto {
  metaData?: LandPersonWithDetailsDtoPagedMetadata;
  items?: LandPersonWithDetailsDto[] | null;
}

export interface LandPersonWithDetailsDtoPersonIdentityDto {
  /** @format uuid */
  id: string;
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /**
   * Отчетство
   * @minLength 1
   */
  middleName: string;
  /**
   * Фамилия
   * @minLength 1
   */
  lastName: string;
  /** Контакты лица */
  personContacts: PersonContactDto[];
  socialRating: LandPersonWithDetailsDtoSocialRatingDto;
}

export interface LandPersonWithDetailsDtoSocialRatingDto {
  /** Эмоциональный статус лица:   *Neutral - Нейтральный   *Positive - Положительный   *Negative - Отрицательный */
  emotionalStatus: LandPersonEmotionalStatus;
  /**
   * Комментарий по лицу
   * @minLength 1
   */
  comment: string;
}

export interface LandToAttachDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
  village: LandToAttachDtoVillageDto;
  /** Собственности участка */
  ownerships: LandToAttachDtoLandOwnershipDto[];
}

export interface LandToAttachDtoLandOwnershipDto {
  /** @format uuid */
  id: string;
  /** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
  status: LandOwnershipStatus;
  /**
   * Индекс собственности
   * @format int32
   */
  index: number;
  /** Добавлено ли лицо к этой собственности */
  isAttached: boolean;
}

export interface LandToAttachDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandToAttachDtoPagedResultDto {
  metaData?: LandToAttachDtoPagedMetadata;
  items?: LandToAttachDto[] | null;
}

export interface LandToAttachDtoVillageDto {
  /** @format uuid */
  id: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
}

export interface LandWithDetailsDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
  /**
   * Id посёлка
   * @format uuid
   */
  villageId: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  villageName: string;
  /**
   * Величина долга
   * @format double
   */
  debt: number;
  /** Статус собственности:   *Created - Создана/неактивна   *Activated - Активирована   *Deactivated - Закрыта/деактивирована */
  status: LandOwnershipStatus;
  /** Собственники */
  owners: LandWithDetailsDtoPersonDto[];
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface LandWithDetailsDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface LandWithDetailsDtoPagedResultDto {
  metaData?: LandWithDetailsDtoPagedMetadata;
  items?: LandWithDetailsDto[] | null;
}

export interface LandWithDetailsDtoPersonDto {
  /** @format uuid */
  id: string;
  /** Optional: Имя */
  firstName?: string | null;
  /** Optional: Отчество */
  middleName?: string | null;
  /** Optional: Фамилия */
  lastName?: string | null;
}

export interface LoginLegacyLegacyLoginDto {
  /**
   * Номер телефона или email
   * @minLength 1
   */
  login: string;
  /**
   * Пароль
   * @minLength 1
   */
  password: string;
  /**
   * GUID устройства
   * @format uuid
   */
  deviceId: string;
  /** Использован номер телефона как логин */
  usePhoneNumberAsLogin: boolean;
  /**
   * Время жизни accessToken в минутах
   * @format int32
   */
  tokenLifetimeInMinutes?: number | null;
}

export interface LoginLegacyLegacyLoginResponseDto {
  /** @minLength 1 */
  accessToken: string;
  /** @minLength 1 */
  refreshToken: string;
  /** @minLength 1 */
  deviceId: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

/** Статус аккаунта:   *Created - Создан   *Activated - Активирован   *Blocked - Заблокирован   *Deleted - Удалено */
export enum LokeoAccountState {
  Created = "Created",
  Activated = "Activated",
  Blocked = "Blocked",
  Deleted = "Deleted",
}

/** Тип аккаунта:   *MobileLokeo - Мобильный Lokeo   *WebLokeoData - Веб LokeoData */
export enum LokeoAccountType {
  MobileLokeo = "MobileLokeo",
  WebLokeoData = "WebLokeoData",
}

export interface PersonCanBeDeletedDto {
  /** @format uuid */
  id: string;
  /** Можно ли удалить лицо */
  canBeDeleted: boolean;
  /** Причина невозможности: является собственником в активной собственности */
  isOwnerInActivatedOwnership: boolean;
  /** Причина невозможности: добавлен к пропускам */
  hasAnyPasses: boolean;
  /** Причина невозможности: добавлен к авто */
  hasAnyVehicles: boolean;
  /** Причина невозможности: добавлен к лицевым счетам */
  hasAnyContracts: boolean;
  /** Причина невозможности: есть аккаунты + приглашения */
  hasAnyAccounts: boolean;
  /** Предупреждение: является собственником в неактивной собственности */
  isOwnerInNotActivatedOwnership: boolean;
  /** Предупреждение: является представителем */
  isRepresentative: boolean;
}

export interface PersonContactDto {
  /**
   * Значение контакта (номер телефона или email)
   * @minLength 1
   */
  value: string;
  /** Тип контакта:   *PhoneNumber - Телефон   *Email - Почта */
  contactType: ContactType;
  /** Использовать для рассылок */
  useForMailing: boolean;
}

/** Контрагент */
export interface PersonCounterpartyDto {
  /** Является контрагентом */
  isCounterparty: boolean;
  /** Тип документа:   *NoDocument - Документ отсутствует   *RussianPassport - Паспорт РФ   *ForeignPassport - Иностранный паспорт   *RussianResidencePermit - ВНЖ РФ   *RussianBirthCertificate - Свидетельство о рождении РФ */
  identityDocumentType: IdentityDocumentType;
  /** Паспорт РФ */
  russianPassport?: PersonRussianPassportIdentityDocumentDto;
  /** Иностранный паспорт */
  foreignPassport?: PersonForeignPassportIdentityDocumentDto;
  /** Свидетельство о рождении РФ */
  russianBirthCertificate?: PersonRussianBirthCertificateIdentityDocumentDto;
  /** ВНЖ РФ */
  russianResidencePermit?: PersonRussianResidencePermitIdentityDocumentDto;
}

/** Иностранный паспорт */
export interface PersonForeignPassportIdentityDocumentDto {
  /**
   * Гражданство
   * @minLength 1
   */
  citizenship: string;
  /**
   * Номер
   * @minLength 1
   */
  number: string;
  /**
   * Орган выдачи
   * @minLength 1
   */
  issuer: string;
  /**
   * Дата выдачи
   * @format date
   */
  issueDate: string;
  /** Пол:   *Female - Женский   *Male - Мужской */
  sex: Sex;
  /**
   * Дата рождения
   * @format date
   */
  birthDate: string;
  /**
   * Место рождения
   * @minLength 1
   */
  birthPlace: string;
  /**
   * Адрес регистрации
   * @minLength 1
   */
  registrationAddress: string;
}

export interface PersonInCardDto {
  /** @format uuid */
  id: string;
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /** Отчество */
  middleName?: string | null;
  /** Фамилия */
  lastName?: string | null;
  /** Аккаунты лица */
  accounts: PersonInCardDtoLokeoAccountDto[];
  /** Контрагент */
  counterparty: PersonCounterpartyDto;
  /** Контакты лица */
  contacts: PersonContactDto[];
  socialRating: PersonInCardDtoPersonIdentitySocialRatingDto;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface PersonInCardDtoLokeoAccountDto {
  /** @format uuid */
  id: string;
  /** Статус аккаунта:   *Created - Создан   *Activated - Активирован   *Blocked - Заблокирован   *Deleted - Удалено */
  state: LokeoAccountState;
  /** Тип аккаунта:   *MobileLokeo - Мобильный Lokeo   *WebLokeoData - Веб LokeoData */
  type: LokeoAccountType;
  /**
   * Логин/телефон
   * @minLength 1
   */
  login: string;
  /**
   * Дата последнего приглашения
   * @format date-time
   */
  lastInviteDateTime?: string | null;
  /**
   * Дата последнего захода в онлайн
   * @format date-time
   */
  lastOnlineDateTime?: string | null;
  /**
   * Дата создания аккаунта
   * @format date-time
   */
  accountCreationDate: string;
}

export interface PersonInCardDtoPersonIdentitySocialRatingDto {
  /** Эмоциональный статус лица:   *Neutral - Нейтральный   *Positive - Положительный   *Negative - Отрицательный */
  emotionalStatus: LandPersonEmotionalStatus;
  /**
   * Комментарий по лицу
   * @minLength 1
   */
  comment: string;
}

export interface PersonInListDto {
  /** @format uuid */
  id: string;
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /** Отчество */
  middleName?: string | null;
  /** Фамилия */
  lastName?: string | null;
  objects: PersonInListDtoObjectContainerDto;
  /** Аккаунты лица */
  accounts: PersonInListDtoLokeoAccountDto[];
  /** Контакты лица */
  contacts: PersonContactDto[];
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface PersonInListDtoContractorDto {
  /** @format uuid */
  id: string;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
}

export interface PersonInListDtoLandDto {
  /** @format uuid */
  id: string;
  name: LandNameDto;
}

export interface PersonInListDtoLandWithVillageDto {
  land: PersonInListDtoLandDto;
  village: PersonInListDtoVillageDto;
}

export interface PersonInListDtoLokeoAccountDto {
  /** @format uuid */
  id: string;
  /** Статус аккаунта:   *Created - Создан   *Activated - Активирован   *Blocked - Заблокирован   *Deleted - Удалено */
  state: LokeoAccountState;
  /** Тип аккаунта:   *MobileLokeo - Мобильный Lokeo   *WebLokeoData - Веб LokeoData */
  type: LokeoAccountType;
  /**
   * Логин/телефон
   * @minLength 1
   */
  login: string;
  /**
   * Дата последнего приглашения
   * @format date-time
   */
  lastInviteDateTime?: string | null;
  /**
   * Дата последнего захода в онлайн
   * @format date-time
   */
  lastOnlineDateTime?: string | null;
  /**
   * Дата создания аккаунта
   * @format date-time
   */
  accountCreationDate: string;
}

export interface PersonInListDtoObjectContainerDto {
  /** Объекты: участки */
  lands: PersonInListDtoLandWithVillageDto[];
  /** Объекты: контрагенты */
  contractors: PersonInListDtoContractorDto[];
}

export interface PersonInListDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface PersonInListDtoPagedResultDto {
  metaData?: PersonInListDtoPagedMetadata;
  items?: PersonInListDto[] | null;
}

export interface PersonInListDtoVillageDto {
  /** @format uuid */
  id: string;
  /**
   * Имя посёлка
   * @minLength 1
   */
  name: string;
}

/** Свидетельство о рождении РФ */
export interface PersonRussianBirthCertificateIdentityDocumentDto {
  /**
   * Серия
   * @minLength 1
   */
  series: string;
  /**
   * Номер
   * @minLength 1
   */
  number: string;
  /**
   * Орган выдачи
   * @minLength 1
   */
  issuer: string;
  /**
   * Дата выдачи
   * @format date
   */
  issueDate: string;
  /** Пол:   *Female - Женский   *Male - Мужской */
  sex: Sex;
  /**
   * Дата рождения
   * @format date
   */
  birthDate: string;
  /**
   * Место рождения
   * @minLength 1
   */
  birthPlace: string;
}

/** Паспорт РФ */
export interface PersonRussianPassportIdentityDocumentDto {
  /**
   * Серия паспорта
   * @minLength 1
   */
  series: string;
  /**
   * Номер паспорта
   * @minLength 1
   */
  number: string;
  /**
   * Код подразделения
   * @minLength 1
   */
  departmentCode: string;
  /**
   * Кем выдан
   * @minLength 1
   */
  issuer: string;
  /**
   * Когда выдан
   * @format date
   */
  issueDate: string;
  /** Пол:   *Female - Женский   *Male - Мужской */
  sex: Sex;
  /**
   * Дата рождения
   * @format date
   */
  birthDate: string;
  /**
   * Место рождения
   * @minLength 1
   */
  birthPlace: string;
  /**
   * Адрес регистрации
   * @minLength 1
   */
  registrationAddress: string;
}

/** ВНЖ РФ */
export interface PersonRussianResidencePermitIdentityDocumentDto {
  /**
   * Гражданство
   * @minLength 1
   */
  citizenship: string;
  /**
   * Номер решения ВНЖ
   * @minLength 1
   */
  decisionNumber: string;
  /**
   * Дата решения
   * @format date
   */
  decisionDate: string;
  /**
   * Серия паспорта
   * @minLength 1
   */
  series: string;
  /**
   * Номер паспорта
   * @minLength 1
   */
  number: string;
  /**
   * Код подразделения
   * @minLength 1
   */
  departmentCode: string;
  /**
   * Орган выдачи
   * @minLength 1
   */
  issuer: string;
  /**
   * Дата выдачи
   * @format date
   */
  issueDate: string;
  /** Пол:   *Female - Женский   *Male - Мужской */
  sex: Sex;
  /**
   * Дата рождения
   * @format date
   */
  birthDate: string;
  /**
   * Место рождения
   * @minLength 1
   */
  birthPlace: string;
  /**
   * Адрес регистрации
   * @minLength 1
   */
  registrationAddress: string;
}

export interface RemoteServiceErrorResponse {
  /** @format int32 */
  statusCode?: number | null;
  error?: RemoteServiceErrorResponseErrorDescription;
}

export interface RemoteServiceErrorResponseErrorDescription {
  message?: string | null;
  code?: string | null;
  details?: string | null;
  stackTrace?: string | null;
  data?: Record<string, any>;
  validationErrors?: Record<string, string[]>;
}

export interface SendInviteForMobileLokeoAccountSendInviteForLokeoAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
  /**
   * Текст приглашения
   * @minLength 1
   */
  text: string;
  /** Сохранить как шаблон */
  saveAsTemplate: boolean;
}

export interface SendInviteForWebLokeoAccountSendInviteForLokeoDataAccountDto {
  /**
   * Id лица
   * @format uuid
   */
  personIdentityId: string;
}

export interface SetLandCadastreDataUpdateCadastreDto {
  /** Optional: Кадастровый номер */
  cadastreNumber?: string | null;
  /**
   * Optional: Площадь участка
   * @format double
   */
  area?: number | null;
  /** Optional: Адрес */
  address?: string | null;
  /** Вид разрешенного использования (ВРИ):   *UseType1 - ИЖС   *UseType2 - ЛПХ   *UseType3 - КФХ   *UseType4 - Сельхозназначения   *UseType5 - Для садоводства */
  allowedUseType?: AllowedUseType;
  /**
   * Optional: Стоимость участка
   * @format double
   */
  cost?: number | null;
  /** Категория земель:   *LandCategory1 - Земли сельскохозяйственного назначения   *LandCategory2 - Земли населенных пунктов   *LandCategory3 - Земли промышленности */
  landCategory?: LandCategory;
}

export interface SetLandOwnershipMailingSettingsSetMailingSettingsDto {
  /** Собственники и представители */
  landPersons: SetMailingSettingsDtoLandPersonDto[];
}

export interface SetMailingSettingsDtoLandPersonDto {
  /** @format uuid */
  id: string;
  /** Рассылка: отчёт по собственности */
  notifyWithOwnershipReport: boolean;
  /** Рассылка: созданные начисления */
  notifyAboutProductAccruals: boolean;
}

export interface SetPersonSocialRatingSetSocialRatingDto {
  /** Эмоциональный статус лица:   *Neutral - Нейтральный   *Positive - Положительный   *Negative - Отрицательный */
  emotionalStatus: LandPersonEmotionalStatus;
  /** Комментарий по лицу */
  comment?: string | null;
}

export interface SetupLandOwnershipSetupLandOwnershipDto {
  /** Тип владения собственностью:   *NotInitialized - Неинициализированный тип   *Private - Индивидуальная   *Common - Общая */
  type: LandOwnershipType;
  /** Типы общей собственности:   *NotInitialized - Неинициализированный тип   *Shares - Долевая   *Joint - Совместная */
  commonType: LandOwnershipCommonType;
  /** Собственники */
  landPersons: SetupLandOwnershipDtoLandPersonDto[];
  settings: SetupLandOwnershipDtoLandOwnershipSettingsDto;
}

export interface SetupLandOwnershipDtoLandOwnershipCadastreDto {
  /** Номер регистрации права */
  registrationNumber?: string | null;
  /**
   * Дата гос.регистрации права
   * @format date
   */
  registrationDate?: string | null;
}

export interface SetupLandOwnershipDtoLandOwnershipDocumentDto {
  /** Тип документа собственности:   *DocumentType1 - Договор купли-продажи   *DocumentType2 - Договор аренды   *DocumentType3 - Свидетельство о праве на наследство   *DocumentType4 - Договор дарения   *DocumentType5 - Судебный приказ */
  documentType: LandOwnershipDocumentType;
  /**
   * Номер документа
   * @minLength 1
   */
  documentNumber: string;
  /**
   * Дата документа
   * @format date
   */
  documentDate: string;
}

export interface SetupLandOwnershipDtoLandOwnershipSettingsDto {
  document: SetupLandOwnershipDtoLandOwnershipDocumentDto;
  cadastre: SetupLandOwnershipDtoLandOwnershipCadastreDto;
}

export interface SetupLandOwnershipDtoLandPersonDto {
  /** @format uuid */
  id: string;
  share: FractionShareDto;
}

/** Пол:   *Female - Женский   *Male - Мужской */
export enum Sex {
  Female = "Female",
  Male = "Male",
}

/** ::: */
export enum SortByParameter {
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface UpdateContractorCommandUpdateContractor {
  /** Тип контрагента:   *LegalEntity - Юридическое лицо   *SoleProprietor - Индивидуальный предприниматель (ИП)   *Individual - Физическое лицо   *SelfEmployed - Самозанятый */
  legalType: ContractorLegalType;
  /**
   * Краткое наименование контрагента
   * @minLength 1
   */
  briefName: string;
  /**
   * Полное наименование контрагента
   * @minLength 1
   */
  fullName: string;
  /**
   * Адрес контрагента
   * @minLength 1
   */
  address: string;
  /** Список банковских реквизитов */
  bankRequisitesCollection: ContractorBankRequisitesDto[];
  management?: ContractorManagementDto;
  classification: ContractorClassificationDto;
  contact: ContractorContactDto;
}

export interface UpdateLandUpdateLandDto {
  /**
   * Номер участка
   * @format int32
   */
  number: number;
  /**
   * Optional: Цифра префикса
   * @format int32
   */
  prefixDigit?: number | null;
  /** Optional: Буква префикса */
  prefixLetter?: string | null;
  /**
   * Optional: Цифра постфикса
   * @format int32
   */
  postfixDigit?: number | null;
  /** Optional: Буква постфикса */
  postfixLetter?: string | null;
}

export interface UpdatePersonIdentityUpdatePersonDto {
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /** Отчество */
  middleName?: string | null;
  /** Фамилия */
  lastName?: string | null;
  /** Контрагент */
  counterparty: PersonCounterpartyDto;
  /** Контакты лица */
  personContacts: PersonContactDto[];
}

export interface VillageCanBeDeletedDto {
  /** @format uuid */
  id: string;
  /** Можно ли удалить посёлок */
  canBeDeleted: boolean;
  /** Причина невозможности: к посёлку добавлены лица */
  hasAnyPersons: boolean;
  /** Причина невозможности: к посёлку добавлены лицевые счета */
  hasAnyContracts: boolean;
}

export interface VillageDto {
  /**
   * Id посёлка
   * @format uuid
   */
  id: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface VillageDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface VillageDtoPagedResultDto {
  metaData?: VillageDtoPagedMetadata;
  items?: VillageDto[] | null;
}

export interface VillageWithDetailsDto {
  /**
   * Id посёлка
   * @format uuid
   */
  id: string;
  /**
   * Наименование посёлка
   * @minLength 1
   */
  name: string;
  /**
   * Количество участков
   * @format int32
   */
  landCount: number;
  /**
   * Значение долга по посёлку в целом
   * @format double
   */
  debt: number;
  /** Является архивированным */
  isDeleted: boolean;
  /**
   * Дата удаления
   * @format date-time
   */
  deletedAt: string;
}

export interface VillageWithDetailsDtoPagedMetadata {
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  totalCount?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

export interface VillageWithDetailsDtoPagedResultDto {
  metaData?: VillageWithDetailsDtoPagedMetadata;
  items?: VillageWithDetailsDto[] | null;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Lokeo.MultiService.Host
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  account = {
    /**
     * No description
     *
     * @tags Account
     * @name ChangeLoginForLokeoAccountCreate
     * @summary Изменить логин для лица участка с аккаунтом
     * @request POST:/api/webapp/account/changeLoginForLokeoAccount
     * @secure
     */
    changeLoginForLokeoAccountCreate: (
      data: ChangeLoginForMobileLokeoAccountChangeLoginForLokeoAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/changeLoginForLokeoAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name ChangeLoginForLokeoDataAccountCreate
     * @summary Изменить логин для лица собственника с аккаунтов
     * @request POST:/api/webapp/account/changeLoginForLokeoDataAccount
     * @secure
     */
    changeLoginForLokeoDataAccountCreate: (
      data: ChangeLoginForWebLokeoAccountChangeLoginForLokeoDataAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/changeLoginForLokeoDataAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name CheckAnyContractsDetail
     * @summary Проверить наличие лицевых счётов у лица перед созданием аккаунта
     * @request GET:/api/webapp/account/{personId}/checkAnyContracts
     * @secure
     */
    checkAnyContractsDetail: (personId: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/${personId}/checkAnyContracts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name CheckMailingIntegrationList
     * @summary Проверить наличие интеграции с сервисом рассылки
     * @request GET:/api/webapp/account/checkMailingIntegration
     * @secure
     */
    checkMailingIntegrationList: (params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/checkMailingIntegration`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name CreateLokeoAccountCreate
     * @summary Создать мобильный аккаунт Lokeo для лица участка (собственника)
     * @request POST:/api/webapp/account/createLokeoAccount
     * @secure
     */
    createLokeoAccountCreate: (data: CreateMobileLokeoAccountCreateLokeoAccountDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/createLokeoAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name CreateLokeoDataAccountCreate
     * @summary Создать веб аккаунт LokeoData для сотрудника
     * @request POST:/api/webapp/account/createLokeoDataAccount
     * @secure
     */
    createLokeoDataAccountCreate: (data: CreateWebLokeoAccountCreateLokeoDataAccountDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/createLokeoDataAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name InviteTemplateList
     * @summary Получить текст шаблона для приглашения в мобильный аккаунт Lokeo
     * @request GET:/api/webapp/account/inviteTemplate
     * @secure
     */
    inviteTemplateList: (params: RequestParams = {}) =>
      this.request<string, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/inviteTemplate`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name SendInviteForLokeoAccountCreate
     * @summary Отправить приглашения для лица участка с аккаунтом
     * @request POST:/api/webapp/account/sendInviteForLokeoAccount
     * @secure
     */
    sendInviteForLokeoAccountCreate: (
      data: SendInviteForMobileLokeoAccountSendInviteForLokeoAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/sendInviteForLokeoAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name SendInviteForLokeoDataAccountCreate
     * @summary Отправить приглашения для сотрудника с аккаунтом
     * @request POST:/api/webapp/account/sendInviteForLokeoDataAccount
     * @secure
     */
    sendInviteForLokeoDataAccountCreate: (
      data: SendInviteForWebLokeoAccountSendInviteForLokeoDataAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/account/sendInviteForLokeoDataAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  contractor = {
    /**
     * No description
     *
     * @tags Contractor
     * @name ArchiveDelete
     * @summary Архивировать контрагента
     * @request DELETE:/api/webapp/contractor/{id}/archive
     * @secure
     */
    archiveDelete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/archive`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name Create
     * @summary Создать контрагента
     * @request POST:/api/webapp/contractor
     * @secure
     */
    create: (data: CreateContractorCommandCreateContractor, params: RequestParams = {}) =>
      this.request<ContractorCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name Delete
     * @summary Удалить контрагента
     * @request DELETE:/api/webapp/contractor/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name Detail
     * @summary Получить карточку контрагента
     * @request GET:/api/webapp/contractor/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<ContractorCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name Update
     * @summary Отредактировать контрагента
     * @request PUT:/api/webapp/contractor/{id}
     * @secure
     */
    update: (id: string, data: UpdateContractorCommandUpdateContractor, params: RequestParams = {}) =>
      this.request<ContractorCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name CanBeDeletedDetail
     * @summary Можно ли удалить контрагента
     * @request GET:/api/webapp/contractor/{id}/canBeDeleted
     * @secure
     */
    canBeDeletedDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContractorCanBeDeletedDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/canBeDeleted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name CanBeEditedDetail
     * @summary Можно ли редактировать контрагента
     * @request GET:/api/webapp/contractor/{id}/canBeEdited
     * @secure
     */
    canBeEditedDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContractorCanBeEditedDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/canBeEdited`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name ContractorPersonCountDetail
     * @summary Получить количество лиц контрагента
     * @request GET:/api/webapp/contractor/{id}/contractorPersonCount
     * @secure
     */
    contractorPersonCountDetail: (
      id: string,
      query?: {
        /** Поиск по имени */
        searchByName?: string;
        /** Фильтрация по ролям лиц контрагентов */
        contractorPersonRoles?: ContractorPersonRole[];
      },
      params: RequestParams = {},
    ) =>
      this.request<number, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/contractorPersonCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name ContractorPersonsDetail
     * @summary Получить список лиц контрагента
     * @request GET:/api/webapp/contractor/{id}/contractorPersons
     * @secure
     */
    contractorPersonsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Поиск по имени */
        searchByName?: string;
        /** Фильтрация по ролям лиц контрагентов */
        contractorPersonRoles?: ContractorPersonRole[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorPersonWithDetailsDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/contractorPersons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name CountList
     * @summary Получить количество контрагентов
     * @request GET:/api/webapp/contractor/count
     * @secure
     */
    countList: (
      query?: {
        /** Поиск по краткому наименованию */
        searchByBriefName?: string;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
        /** Фильтрация по типу контрагента */
        legalTypes?: ContractorLegalType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<number, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name DadataDetail
     * @summary Получить данные контрагента по ИНН из dadata
     * @request GET:/api/webapp/contractor/dadata/{inn}
     * @secure
     */
    dadataDetail: (inn: string, params: RequestParams = {}) =>
      this.request<GetContractorFromDadataDadataContactorResponseDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/dadata/${inn}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name PagedList
     * @summary Получить список контрагентов
     * @request GET:/api/webapp/contractor/paged
     * @secure
     */
    pagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Поиск по краткому наименованию */
        searchByBriefName?: string;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
        /** Фильтрация по типу контрагента */
        legalTypes?: ContractorLegalType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorWithDetailsDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/paged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name MinimalPagedList
     * @summary Получить список контрагентов с минимальными данными (для фильтров)
     * @request GET:/api/webapp/contractor/minimalPaged
     * @secure
     */
    minimalPagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Поиск по краткому наименованию */
        searchByBriefName?: string;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
        /** Фильтрация по типу контрагента */
        legalTypes?: ContractorLegalType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/minimalPaged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contractor
     * @name RestoreCreate
     * @summary Восстановить контрагента из архива
     * @request POST:/api/webapp/contractor/{id}/restore
     * @secure
     */
    restoreCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractor/${id}/restore`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contractorPerson = {
    /**
     * No description
     *
     * @tags ContractorPerson
     * @name Create
     * @summary Присоединить лицо к контрагенту
     * @request POST:/api/webapp/contractorPerson
     * @secure
     */
    create: (data: CreateContractorPersonAttachPersonToContractorDto, params: RequestParams = {}) =>
      this.request<ContractorPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractorPerson`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractorPerson
     * @name Delete
     * @summary Отвязать лицо от контрагента
     * @request DELETE:/api/webapp/contractorPerson/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractorPerson/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractorPerson
     * @name Detail
     * @summary Получить карточку лица контрагента
     * @request GET:/api/webapp/contractorPerson/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<ContractorPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/contractorPerson/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  land = {
    /**
     * No description
     *
     * @tags Land
     * @name ArchiveDelete
     * @summary Архивировать участок
     * @request DELETE:/api/webapp/land/{id}/archive
     * @secure
     */
    archiveDelete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}/archive`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name CheckOverlappingIntervalCreate
     * @summary Проверить диапазон на наличие в нём ранее созданных участков
     * @request POST:/api/webapp/land/checkOverlappingInterval
     * @secure
     */
    checkOverlappingIntervalCreate: (data: CheckLandIntervalCheckLandIntervalDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/checkOverlappingInterval`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name Create
     * @summary Одиночное создание участка
     * @request POST:/api/webapp/land
     * @secure
     */
    create: (data: CreateLandCreateLandDto, params: RequestParams = {}) =>
      this.request<LandCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name ManyCreate
     * @summary Массовое создание участков
     * @request POST:/api/webapp/land/many
     * @secure
     */
    manyCreate: (data: CreateManyLandsCreateManyLandsDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/many`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name Delete
     * @summary Удалить участок
     * @request DELETE:/api/webapp/land/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name Detail
     * @summary Получить карточку участка
     * @request GET:/api/webapp/land/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<LandCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name Update
     * @summary Отредактировать существующий участок
     * @request PUT:/api/webapp/land/{id}
     * @secure
     */
    update: (id: string, data: UpdateLandUpdateLandDto, params: RequestParams = {}) =>
      this.request<LandCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name CanBeDeletedDetail
     * @summary Можно ли удалить участок
     * @request GET:/api/webapp/land/{id}/canBeDeleted
     * @secure
     */
    canBeDeletedDetail: (id: string, params: RequestParams = {}) =>
      this.request<LandCanBeDeletedDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}/canBeDeleted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name MinimalPagedList
     * @summary Получить список участков с минимальными данными (для фильтров)
     * @request GET:/api/webapp/land/minimalPaged
     * @secure
     */
    minimalPagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по номеру участка */
        searchByNumber?: string;
        /** Optional: Сортировать по номеру участка */
        sortByLandNumber?: SortByParameter;
        /** Optional: Сортировать по величине долга */
        sortByDebt?: SortByParameter;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<LandDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/minimalPaged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name LandOwnershipMinimalPagedDetail
     * @summary Получить список собственности участка с минимальными данными (для фильтров, для селектов)
     * @request GET:/api/webapp/land/{id}/landOwnershipMinimalPaged
     * @secure
     */
    landOwnershipMinimalPagedDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Фильтрация по статусу собственности */
        landOwnershipStatuses?: LandOwnershipStatus[];
        /** Optional: Сортировать по величине долга */
        sortByIndex?: SortByParameter;
      },
      params: RequestParams = {},
    ) =>
      this.request<LandOwnershipDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}/landOwnershipMinimalPaged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name RestoreCreate
     * @summary Восстановить участок из архивации
     * @request POST:/api/webapp/land/{id}/restore
     * @secure
     */
    restoreCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}/restore`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Land
     * @name CadastreCreate
     * @summary Обновить кадастровые данные участка
     * @request POST:/api/webapp/land/{id}/cadastre
     * @secure
     */
    cadastreCreate: (id: string, data: SetLandCadastreDataUpdateCadastreDto, params: RequestParams = {}) =>
      this.request<LandCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/land/${id}/cadastre`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  landOwnership = {
    /**
     * No description
     *
     * @tags LandOwnership
     * @name ActivateCreate
     * @summary Активировать собственность
     * @request POST:/api/webapp/landOwnership/{id}/activate
     * @secure
     */
    activateCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/activate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name CheckActivationCreate
     * @summary Проверить возможность активации собственности
     * @request POST:/api/webapp/landOwnership/{id}/checkActivation
     * @secure
     */
    checkActivationCreate: (id: string, params: RequestParams = {}) =>
      this.request<CheckLandOwnershipActivationCheckActivationDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/checkActivation`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name CheckDeactivationCreate
     * @summary Проверить возможность закрытия/деактивации собственности
     * @request POST:/api/webapp/landOwnership/{id}/checkDeactivation
     * @secure
     */
    checkDeactivationCreate: (id: string, params: RequestParams = {}) =>
      this.request<CheckLandOwnershipDeactivationCheckDeactivationDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/checkDeactivation`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name DeactivateCreate
     * @summary Закрыть/деактивировать собственность
     * @request POST:/api/webapp/landOwnership/{id}/deactivate
     * @secure
     */
    deactivateCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/deactivate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name CheckMailingDetail
     * @summary Проверить настройки рассылки
     * @request GET:/api/webapp/landOwnership/{id}/checkMailing
     * @secure
     */
    checkMailingDetail: (id: string, params: RequestParams = {}) =>
      this.request<CheckLandOwnershipMailingDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/checkMailing`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name Detail
     * @summary Получить карточку собственности
     * @request GET:/api/webapp/landOwnership/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<LandOwnershipWithDetailsDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name LandPersonCountDetail
     * @summary Получить количество лиц собственности
     * @request GET:/api/webapp/landOwnership/{id}/landPersonCount
     * @secure
     */
    landPersonCountDetail: (
      id: string,
      query?: {
        /** Фильтрация по ролям лиц участков */
        landPersonRoles?: LandPersonRole[];
      },
      params: RequestParams = {},
    ) =>
      this.request<number, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/landPersonCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name LandPersonsDetail
     * @summary Получить список лиц собственности
     * @request GET:/api/webapp/landOwnership/{id}/landPersons
     * @secure
     */
    landPersonsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Фильтрация по ролям лиц участков */
        landPersonRoles?: LandPersonRole[];
      },
      params: RequestParams = {},
    ) =>
      this.request<LandPersonWithDetailsDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/landPersons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name SetMailingSettingsCreate
     * @summary Задать настройки рассылки
     * @request POST:/api/webapp/landOwnership/{id}/setMailingSettings
     * @secure
     */
    setMailingSettingsCreate: (
      id: string,
      data: SetLandOwnershipMailingSettingsSetMailingSettingsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/setMailingSettings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandOwnership
     * @name SetupCreate
     * @summary Настроить собственность
     * @request POST:/api/webapp/landOwnership/{id}/setup
     * @secure
     */
    setupCreate: (id: string, data: SetupLandOwnershipSetupLandOwnershipDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/landOwnership/${id}/setup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  landPerson = {
    /**
     * No description
     *
     * @tags LandPerson
     * @name Create
     * @summary Присоединить лицо к участку
     * @request POST:/api/webapp/landPerson
     * @secure
     */
    create: (data: CreateLandPersonAttachPersonToLandDto, params: RequestParams = {}) =>
      this.request<LandPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandPerson
     * @name Delete
     * @summary Отвязать лицо от участка
     * @request DELETE:/api/webapp/landPerson/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandPerson
     * @name Detail
     * @summary Получить карточку лица участка
     * @request GET:/api/webapp/landPerson/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<LandPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandPerson
     * @name MakeOwnerCreate
     * @summary Сделать лицо участка собственником
     * @request POST:/api/webapp/landPerson/{id}/makeOwner
     * @secure
     */
    makeOwnerCreate: (id: string, params: RequestParams = {}) =>
      this.request<LandPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson/${id}/makeOwner`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandPerson
     * @name MakeRepresentativeCreate
     * @summary Сделать лицо участка представителем
     * @request POST:/api/webapp/landPerson/{id}/makeRepresentative
     * @secure
     */
    makeRepresentativeCreate: (id: string, params: RequestParams = {}) =>
      this.request<LandPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson/${id}/makeRepresentative`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LandPerson
     * @name MakeVisitorCreate
     * @summary Сделать лицо участка посетителем (из собственника или представителя)
     * @request POST:/api/webapp/landPerson/{id}/makeVisitor
     * @secure
     */
    makeVisitorCreate: (id: string, params: RequestParams = {}) =>
      this.request<LandPersonDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/landPerson/${id}/makeVisitor`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  legacyIdentity = {
    /**
     * No description
     *
     * @tags Legacy Identity
     * @name AuthenticationV2CreateCreate
     * @summary Легаси: авторизоваться в Web LokeoData
     * @request POST:/api/authentication/v2/create
     * @secure
     */
    authenticationV2CreateCreate: (data: CreateIdentityLegacyCreateIdentityLegacyDto, params: RequestParams = {}) =>
      this.request<EntityResultDto, RemoteServiceErrorResponse>({
        path: `/api/authentication/v2/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Legacy Identity
     * @name AuthenticationV2LoginCreate
     * @summary Легаси: авторизоваться в Web LokeoData
     * @request POST:/api/authentication/v2/login
     * @secure
     */
    authenticationV2LoginCreate: (data: LoginLegacyLegacyLoginDto, params: RequestParams = {}) =>
      this.request<LoginLegacyLegacyLoginResponseDto, RemoteServiceErrorResponse>({
        path: `/api/authentication/v2/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  person = {
    /**
     * No description
     *
     * @tags Person
     * @name ArchiveDelete
     * @summary Архивировать лицо
     * @request DELETE:/api/webapp/person/{id}/archive
     * @secure
     */
    archiveDelete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/archive`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name Create
     * @summary Создать новое лицо
     * @request POST:/api/webapp/person
     * @secure
     */
    create: (data: CreatePersonIdentityCreatePersonDto, params: RequestParams = {}) =>
      this.request<PersonInCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name Delete
     * @summary Удалить лицо
     * @request DELETE:/api/webapp/person/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name Detail
     * @summary Получить карточку лица
     * @request GET:/api/webapp/person/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<PersonInCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name Update
     * @summary Отредактировать лицо
     * @request PUT:/api/webapp/person/{id}
     * @secure
     */
    update: (id: string, data: UpdatePersonIdentityUpdatePersonDto, params: RequestParams = {}) =>
      this.request<PersonInCardDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CanBeDeletedDetail
     * @summary Можно ли удалить лицо
     * @request GET:/api/webapp/person/{id}/canBeDeleted
     * @secure
     */
    canBeDeletedDetail: (id: string, params: RequestParams = {}) =>
      this.request<PersonCanBeDeletedDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/canBeDeleted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name ContractorsDetail
     * @summary Получить список контрагентов лица
     * @request GET:/api/webapp/person/{id}/contractors
     * @secure
     */
    contractorsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorPersonListInPersonCardDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/contractors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name ContractorsToAttachDetail
     * @summary Получить список контрагентов для добавления лица
     * @request GET:/api/webapp/person/{id}/contractorsToAttach
     * @secure
     */
    contractorsToAttachDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по краткому наименованию контрагента */
        searchByBriefName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorToAttachDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/contractorsToAttach`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CountList
     * @request GET:/api/webapp/person/count
     * @secure
     */
    countList: (
      query?: {
        /** Поиск по имени */
        searchByName?: string;
        /** Фильтрация по посёлку */
        villageIds?: string[];
        /** Фильтрация по контрагенту */
        contractorIds?: string[];
        /** Фильтрация по ролям лиц участков */
        landPersonRoles?: LandPersonRole[];
        /** Фильтрация по ролям лиц контрагента */
        contractorPersonRoles?: ContractorPersonRole[];
        /** Фильтрация по типу аккаунтов */
        accountTypes?: LokeoAccountType[];
        /** Фильтрация по состоянию аккаунтов LokeoData */
        lokeoDataAccountStates?: LokeoAccountState[];
        /** Фильтрация по состоянию аккаунтов Lokeo */
        lokeoAccountStates?: LokeoAccountState[];
        /** Только собственники (лица участков) */
        onlyLandPersons?: boolean;
        /** Только сотрудники (лица контрагентов) */
        onlyContractorPersons?: boolean;
        /** Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LandsDetail
     * @summary Получить список участков лица (в разрезе собственности)
     * @request GET:/api/webapp/person/{id}/lands
     * @secure
     */
    landsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LandPersonListInPersonCardDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/lands`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LandsToAttachDetail
     * @summary Получить список участков для добавления лица
     * @request GET:/api/webapp/person/{id}/landsToAttach
     * @secure
     */
    landsToAttachDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по номеру участка */
        searchByNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LandToAttachDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/landsToAttach`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PagedList
     * @summary Получить список лиц
     * @request GET:/api/webapp/person/paged
     * @secure
     */
    pagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Поиск по имени */
        searchByName?: string;
        /** Фильтрация по посёлку */
        villageIds?: string[];
        /** Фильтрация по контрагенту */
        contractorIds?: string[];
        /** Фильтрация по ролям лиц участков */
        landPersonRoles?: LandPersonRole[];
        /** Фильтрация по ролям лиц контрагента */
        contractorPersonRoles?: ContractorPersonRole[];
        /** Фильтрация по типу аккаунтов */
        accountTypes?: LokeoAccountType[];
        /** Фильтрация по состоянию аккаунтов LokeoData */
        lokeoDataAccountStates?: LokeoAccountState[];
        /** Фильтрация по состоянию аккаунтов Lokeo */
        lokeoAccountStates?: LokeoAccountState[];
        /** Только собственники (лица участков) */
        onlyLandPersons?: boolean;
        /** Только сотрудники (лица контрагентов) */
        onlyContractorPersons?: boolean;
        /** Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PersonInListDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/paged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name RestoreCreate
     * @summary Восстановить лицо из архивации
     * @request POST:/api/webapp/person/{id}/restore
     * @secure
     */
    restoreCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/restore`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name SetSocialRatingCreate
     * @summary Установить эмоциональный статус лица
     * @request POST:/api/webapp/person/{id}/setSocialRating
     * @secure
     */
    setSocialRatingCreate: (id: string, data: SetPersonSocialRatingSetSocialRatingDto, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/person/${id}/setSocialRating`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  village = {
    /**
     * No description
     *
     * @tags Village
     * @name ArchiveDelete
     * @summary Архивировать посёлок
     * @request DELETE:/api/webapp/village/{id}/archive
     * @secure
     */
    archiveDelete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}/archive`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name Update
     * @summary Обновить существующий посёлок
     * @request PUT:/api/webapp/village/{id}
     * @secure
     */
    update: (id: string, data: ChangeVillageNameUpdateVillageDto, params: RequestParams = {}) =>
      this.request<VillageWithDetailsDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name Delete
     * @summary Удалить посёлок
     * @request DELETE:/api/webapp/village/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name Detail
     * @summary Получить карточку посёлка
     * @request GET:/api/webapp/village/{id}
     * @secure
     */
    detail: (id: string, params: RequestParams = {}) =>
      this.request<VillageWithDetailsDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name Create
     * @summary Создать новый посёлок
     * @request POST:/api/webapp/village
     * @secure
     */
    create: (data: CreateVillageCreateVillageDto, params: RequestParams = {}) =>
      this.request<VillageWithDetailsDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name CanBeDeletedDetail
     * @summary Можно ли удалить посёлок
     * @request GET:/api/webapp/village/{id}/canBeDeleted
     * @secure
     */
    canBeDeletedDetail: (id: string, params: RequestParams = {}) =>
      this.request<VillageCanBeDeletedDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}/canBeDeleted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name LandsDetail
     * @summary Получить список участков посёлка
     * @request GET:/api/webapp/village/{id}/lands
     * @secure
     */
    landsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по номеру участка */
        searchByNumber?: string;
        /** Optional: Сортировать по номеру участка */
        sortByLandNumber?: SortByParameter;
        /** Optional: Сортировать по величине долга */
        sortByDebt?: SortByParameter;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<LandWithDetailsDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}/lands`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name PagedList
     * @summary Получить список посёлков
     * @request GET:/api/webapp/village/paged
     * @secure
     */
    pagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по наименованию */
        searchByName?: string;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<VillageWithDetailsDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/paged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name MinimalPagedList
     * @summary Получить список посёлков с минимальными данными (для фильтров)
     * @request GET:/api/webapp/village/minimalPaged
     * @secure
     */
    minimalPagedList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        /** Optional: Поиск по наименованию */
        searchByName?: string;
        /** Optional: Вместе с архивированными */
        withArchived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<VillageDtoPagedResultDto, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/minimalPaged`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Village
     * @name RestoreCreate
     * @summary Восстановить посёлок из архивации
     * @request POST:/api/webapp/village/{id}/restore
     * @secure
     */
    restoreCreate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, RemoteServiceErrorResponse>({
        path: `/api/webapp/village/${id}/restore`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
