<template>
  <div
    class="app-dropdown"
    :class="{ 'app-dropdown--active': isVisible }"
    :ref="(el) => (dropdownTargetRef = (el as Element)?.children?.[0])"
    v-on="buttonEventHandlers"
    :data-test-id="testId">
    <slot :is-active="isVisible" />
  </div>

  <Teleport to="#app-dropdown-items">
    <div
      ref="itemsContainerRef"
      v-on="containerEventHandlers">
      <Transition
        :name="transitionName"
        mode="out-in"
        appear>
        <div
          v-if="isVisible"
          class="app-dropdown__content"
          :class="[customClass]"
          :data-test-id="testId">
          <slot
            name="container"
            :close="close" />
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import debounce from '@/helpers/debounce';
import { MaybeElementRef, onClickOutside } from '@vueuse/core';
import { usePopper, UsePopperPlacement } from '@/compositions';

interface Props {
  trigger?: 'hover' | 'click';
  hideDelay?: number;
  hoverDelay?: number;
  placement?: UsePopperPlacement;
  offset?: [number, number];
  transitionName?: string;
  customClass?: string;
  testId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  trigger: 'hover',
  hideDelay: 200,
  hoverDelay: 200,
  placement: 'bottom-start',
  offset: () => [0, 6],
  transitionName: 'transition',
});

const globalStore = useGlobalStore();

const dropdownTargetRef = ref<Element>(null);
const itemsContainerRef = ref<HTMLDivElement>(null);

const { isVisible, open, close } = usePopper(dropdownTargetRef, itemsContainerRef, { placement: props.placement, offset: props.offset });

defineExpose({ close });

const isOutsideOfContainer = ref(true);
const isOutsideOfButton = ref(true);

const buttonEventHandlers = computed(() => {
  const handlers = {
    click: (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      isVisible.value = !isVisible.value;
    },
  };

  // Это нужно для авто тестирования, что бы дропдаун не пропадал
  console.log('APP_RUN_TESTING', globalStore.APP_RUN_TESTING)
  if (globalStore.APP_RUN_TESTING) return handlers;

  if (props.trigger === 'hover') {
    handlers['mouseenter'] = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      openPopper();
      isOutsideOfButton.value = false;
    };

    handlers['mouseleave'] = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      isOutsideOfButton.value = true;
    };
  }


  return handlers;

  if (props.trigger === 'hover') {
    return {
      ['mouseenter']: (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        openPopper();
        isOutsideOfButton.value = false;
      },

      ['mouseleave']: (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        isOutsideOfButton.value = true;
      },
    };
  } else if (props.trigger === 'click') {
    return {
      ['click']: (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        isVisible.value = !isVisible.value;
      },
    };
  }
});

const containerEventHandlers = computed(() => {
  if (globalStore.APP_RUN_TESTING) return {};

  if (props.trigger === 'hover') {
    return {
      ['mouseenter']: () => {
        openPopper();
        isOutsideOfContainer.value = false;
      },

      ['mouseleave']: () => {
        isOutsideOfContainer.value = true;
      },
    };
  } else if (props.trigger === 'click') {
    return {};
  }
});

const openPopperDebounce = debounce(open, props.hoverDelay);
const closePopperDebounce = debounce(close, props.hideDelay);

const openPopper = async () => {
  closePopperDebounce.clear();
  openPopperDebounce();
};

const closePopper = async () => {
  openPopperDebounce.clear();
  closePopperDebounce();
};

watch([isOutsideOfContainer, isOutsideOfButton], () => {
  if (isOutsideOfContainer.value && isOutsideOfButton.value) {
    closePopper();
  } else {
    openPopper();
  }
});

onClickOutside(itemsContainerRef, () => (isVisible.value = false), { ignore: [dropdownTargetRef as MaybeElementRef] });
</script>

<style lang="scss" scoped>
.app-dropdown {
  display: contents;
}

.app-dropdown__content {
  display: flex;
  width: max-content;
  flex-direction: column;
  background-color: var(--neutral-100);
  border-radius: 12px;
  overflow: hidden;

  box-shadow: 0px 8px 50px 0px rgba(0, 82, 57, 0.15);

  &.transition-enter-active {
    animation: app-menu-button-transition 0.13s ease;
  }

  &.transition-leave-active {
    animation: app-menu-button-transition 0.13s ease reverse;
  }

  @keyframes app-menu-button-transition {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
