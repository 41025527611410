import { http } from '@/http';
import { useEventBus } from '@vueuse/core';
import { successNotification, errorNotification } from '@/helpers';
import { useDialog } from '../useDialog';

const villagesBus = useEventBus('villages');

export type UseVillagesReturnValue = ReturnType<typeof useVillages>;

export function useVillages({ onChange, onDeleted } = { onChange: null, onDeleted: null }) {
  const events = {
    'villages-changed': onChange,
    'village-deleted': onDeleted,
  };

  const eventListenter = (e) => events[e]?.();

  if (onChange instanceof Function) {
    villagesBus.on(eventListenter);
  }

  async function openCreateVillageDialog() {
    console.log('openCreateVillageDialog');

    useDialog(
      (await import('@/components/Villages/EditVillageDialog.vue')).default,
      {
        title: 'Создание посёлка',
        hideFooter: true,
        confirmButtonConfig: {
          type: 'primary',
          title: 'Создать',
        },

        confirm: createVillage,
      },

      {
        name: null,
      }
    );
  }

  async function createVillage(name: string) {
    try {
      await http.village.create({ name });

      successNotification(`Посёлок <span class="text-bold">${name}</span> создан и готов к использованию`, 'Посёлок успешно создан', true);
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Посёлок не создан');

      throw error;
    } finally {
      villagesBus.emit('villages-changed');
    }
  }

  async function openEditVillageDialog({ id, name }: { id: string; name: string }) {
    console.log('openCreateVillageDialog');

    useDialog(
      (await import('@/components/Villages/EditVillageDialog.vue')).default,

      {
        title: 'Редактирование посёлка',
        hideFooter: true,
        confirmButtonConfig: {
          type: 'primary',
          title: 'Сохранить',
        },
        confirm: editVillage,
      },

      {
        id,
        name,
        oldName: name,
      }
    );
  }

  async function editVillage(id: string, name: string, oldName: string) {
    try {
      await http.village.update(id, { name });

      successNotification(`Посёлок <span class="text-bold">${oldName}</span> успешно изменён на <span class="text-bold">${name}</span> `, 'Посёлок успешно изменён', true);
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Посёлок не создан');

      throw error;
    } finally {
      villagesBus.emit('villages-changed');
    }
  }

  async function checkVillageCanBeDeleted(id: string) {
    try {
      const { data } = await http.village.canBeDeletedDetail(id);

      return data;
    } catch (error) {
      errorNotification('Не удалось проверить посёлок на возможность удаления. Попробуйте позже.', 'Произошла ошибка');

      throw error;
    }
  }

  function openDeleteVillageDialog({ id, name }: { id: string; name: string }) {
    useDialog(
      'Вы уверены, что хотите удалить данный посёлок? После процедуры удаления восстановление будет невозможно.',

      {
        title: 'Удаление посёлка',
        confirmButtonConfig: {
          type: 'primary',
          title: 'Удалить',
        },
        confirm: async () => await deleteVillage(id, name),
      }
    );
  }

  async function deleteVillage(id: string, name: string) {
    try {
      await http.village.delete(id);

      successNotification(`Посёлок <span class="text-bold">${name}</span> успешно удалён`, 'Посёлок удалён', true);
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось удалить посёлок');
      throw error;
    } finally {
      villagesBus.emit('village-deleted');
    }
  }

  async function openArchiveVillageDialog({ id, name }: { id: string; name: string }) {
    useDialog(
      (await import('@/components/Villages/ArchiveVillageDialog.vue')).default,

      {
        title: 'Архивация посёлка',
        hideFooter: true,
        confirm: async () => await archiveVillage({ id, name }),
      },
      {}
    );
  }

  async function archiveVillage({ id, name }: { id: string; name: string }) {
    try {
      await http.village.archiveDelete(id);

      successNotification(`Посёлок <span class="text-bold">${name}</span> успешно архивирован`, 'Посёлок архивирован', true);
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось архивировать посёлок');
      throw error;
    } finally {
      villagesBus.emit('villages-changed');
    }
  }

  async function restoreVillage({ id, name }: { id: string; name: string }) {
    try {
      await http.village.restoreCreate(id);

      successNotification(`Посёлок <span class="text-bold">${name}</span> успешно восстановлен`, 'Посёлок восстановлен', true);
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось восстановить посёлок');

      throw error;
    } finally {
      villagesBus.emit('villages-changed');
    }
  }

  onBeforeUnmount(() => {
    villagesBus.off(eventListenter);
  });

  return {
    openCreateVillageDialog,
    openEditVillageDialog,
    openDeleteVillageDialog,
    openArchiveVillageDialog,

    createVillage,
    editVillage,
    restoreVillage,

    checkVillageCanBeDeleted,
  };
}
