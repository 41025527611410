import { AppRouteRecord } from '../types';

import { authRoutes } from './auth';
import { contractorsRoutes } from './contractors';
import { landOwnershipsRoutes } from './land-ownerships';
import { landRoutes } from './land';
import { personsRoutes } from './persons';
import { startRoutes } from './start';
import { villagesRoutes } from './villages';
import { devRoutes } from './dev';
// import { genplanRoutes } from './genplan';

import PageMain from '@/pages/PageMain.vue';
import Page404 from '@/pages/Page404.vue';

export default [
  {
    path: '/',
    name: 'root',
    component: PageMain,
    redirect: { name: 'start' },
    children: [...startRoutes, ...devRoutes, ...villagesRoutes, ...personsRoutes, ...landRoutes, ...landOwnershipsRoutes, ...contractorsRoutes],
  },
  ...authRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Page404,
  },
] as const satisfies readonly AppRouteRecord[];
