import { loadImport } from '../helpers';
import { AppRouteRecord } from '../';

export const contractorsRoutes = [
  {
    name: 'contractors.table',
    path: 'contractors',
    component: () => loadImport('../pages/PageContractors/PageContractors.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    name: 'contractors.form',
    path: 'contractors/:contractorId',
    component: () => loadImport('../pages/PageEditContractors/PageEditContractors.vue'),
    meta: {
      requiresAuth: true,
      isPageDetail: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
