<template>
  <AppDrawer
    :model-value="drawerStore.isOpen"
    :width="drawerWidth"
    @update:model-value="drawerStore.closeDrawer({ clear: true })"
    @back="drawerStore.goBack">
    <!-- <pre style="font-size: 11px">{{ drawerStore.drawersStack }}</pre> -->

    <div class="drawer-content">
      <component
        :is="drawerStore.currentDrawer.component"
        v-bind="{ ...drawerStore.currentDrawer.queryParams, ...$attrs }" />
    </div>
  </AppDrawer>
</template>

<script lang="ts" setup>
const route = useRoute();

const drawerStore = useDrawerStore();
const sidebarStore = useSidebarStore();

const drawerWidth = computed(() => {
  return `calc(100vw - ${sidebarStore.isCompact ? '64px' : '224px'} - 50px)`;
});

const currentQueryParams = computed(() => {
  const params = { ...route.query };

  delete params.d;
  return params;
});

watch(
  () => route.name,
  () => {
    if (route.query.d) {
      console.log('Drawer detected', route.query, currentQueryParams.value);
      drawerStore.openDrawer({ name: route.query.d, queryParams: currentQueryParams.value });
    } else {
      drawerStore.closeDrawer();
    }
  }
);
</script>

<style lang="scss">
.drawer-content {
  width: 100%;
  height: 100%;
}
</style>
