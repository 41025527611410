import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const personsRoutes = [
  {
    name: 'persons',
    path: 'persons',
    component: () => loadImport('../pages/PagePersons/PagePersons.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    name: 'edit-persons',
    path: 'persons/:personId',
    component: () => loadImport('../pages/PageEditPersons/PageEditPersons.vue'),
    meta: {
      requiresAuth: true,
      isPageDetail: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
