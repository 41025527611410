import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const villagesRoutes = [
  {
    name: 'villages',
    path: 'villages',
    component: () => loadImport('../pages/PageVillages/PageVillages.vue'),
    meta: {
      requiresAuth: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
