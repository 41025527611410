export const useZIndexCounter = (function (startIndex: number) {
  return new Proxy(
    {
      value: startIndex,
    },
    {
      get(target, prop) {
        if (prop === 'value') {
          target.value += 1;
          return target.value;
        }
        return target[prop];
      },
    }
  );
})(1000);
