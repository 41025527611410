import { http } from '@/http';
import { successNotification, errorNotification, landNumberStringParsing, useInputNumberRef } from '@/helpers';
import { useEventBus } from '@vueuse/core';
import { useDialog } from '../useDialog';
import { LandDto } from '@/http/api';

type UseLandsParams = {
  onLandsChanged?: (...args: any[]) => any;
};

export type LandForm = {
  id: string | null;
  isMassCreate: boolean;
  hasPrefix: boolean;
  hasPostfix: boolean;
  name: {
    number: Ref<number | null>;
    startNumber: Ref<number | null>;
    endNumber: Ref<number | null>;
    postfixDigit: Ref<number | null>;
    postfixLetter: string | null;
    prefixDigit: Ref<number | null>;
    prefixLetter: string | null;
  };
};

export type UseLandsReturnValue = ReturnType<typeof useLands>;

const landsBus = useEventBus('lands');

export function useLands({ onLandsChanged }: UseLandsParams = { onLandsChanged: () => {} }) {
  const events = {
    'lands-changed': onLandsChanged,
  };

  const eventListenter = (e) => events[e]?.();

  if (onLandsChanged instanceof Function) {
    landsBus.on(eventListenter);
  }

  const getCreateLandsForm = () => ({
    id: null,
    isMassCreate: false,
    hasPrefix: false,
    hasPostfix: false,
    name: {
      number: useInputNumberRef(null),
      startNumber: useInputNumberRef(null),
      endNumber: useInputNumberRef(null),
      postfixDigit: useInputNumberRef(null),
      postfixLetter: null,
      prefixDigit: useInputNumberRef(null),
      prefixLetter: null,
    },
  });

  async function onOpenCreateLandsDialog(village: { id: string; name: string }) {
    useDialog(
      (await import('@/components/Lands/CreateLandsDialog.vue')).default,

      {
        title: 'Добавление участков',
        hideFooter: true,
        confirm: createLands,
      },

      {
        land: getCreateLandsForm(),
        village,
      }
    );
  }

  async function createLands(villageId: string, createLandsForm: LandForm) {
    try {
      if (createLandsForm.isMassCreate) {
        const payload = {
          villageId,
          startNumber: createLandsForm.name.startNumber,
          endNumber: createLandsForm.name.endNumber,
          prefixDigit: createLandsForm.name.prefixDigit,
          prefixLetter: createLandsForm.name.prefixLetter,
          postfixDigit: createLandsForm.name.postfixDigit,
          postfixLetter: createLandsForm.name.postfixLetter,
        };

        const { data: land } = await http.land.manyCreate(payload);

        const firstLandNumber = {
          number: createLandsForm.name.startNumber,
          prefixDigit: createLandsForm.name.prefixDigit,
          prefixLetter: createLandsForm.name.prefixLetter,
          postfixDigit: createLandsForm.name.postfixDigit,
          postfixLetter: createLandsForm.name.postfixLetter,
        };

        const lastLandNumber = {
          number: createLandsForm.name.endNumber,
          prefixDigit: createLandsForm.name.prefixDigit,
          prefixLetter: createLandsForm.name.prefixLetter,
          postfixDigit: createLandsForm.name.postfixDigit,
          postfixLetter: createLandsForm.name.postfixLetter,
        };

        successNotification(
          `
          Участки в диапазоне с <span class="text-bold">${landNumberStringParsing(firstLandNumber)}</span> по <span class="text-bold">${landNumberStringParsing(
            lastLandNumber
          )}</span> созданы и готовы к использованию
          `,
          'Участки успешно созданы',
          true
        );
      } else {
        const payload = {
          villageId,
          number: createLandsForm.name.number,
          prefixDigit: createLandsForm.name.prefixDigit,
          prefixLetter: createLandsForm.name.prefixLetter,
          postfixDigit: createLandsForm.name.postfixDigit,
          postfixLetter: createLandsForm.name.postfixLetter,
        };

        const { data: land } = await http.land.create(payload);

        successNotification(`Участок <span class="text-bold">${landNumberStringParsing(land.name)}</span> создан и готов к использованию`, 'Участок успешно создан', true);
      }

      landsBus.emit('lands-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Участок не создан');

      throw error;
    }
  }

  const oldLandName = reactive({
    number: null,
    prefixDigit: null,
    prefixLetter: null,
    postfixDigit: null,
    postfixLetter: null,
  });

  async function onOpenEditLandDialog(village: { id: string; name: string }, land: { name: LandForm['name'] }) {
    Object.assign(oldLandName, land.name);

    useDialog(
      (await import('@/components/Lands/CreateLandsDialog.vue')).default,

      {
        title: 'Редактирование участка',
        hideFooter: true,
        confirm: editLand,
      },

      {
        land: {
          ...land,
          hasPostfix: !!land.name.postfixLetter || !!land.name.postfixDigit,
          hasPrefix: !!land.name.prefixLetter || !!land.name.prefixDigit,
        },
        village,
      }
    );
  }

  async function editLand(editLandForm: LandForm) {
    try {
      await http.land.update(editLandForm.id, {
        number: editLandForm.name.number,
        prefixDigit: editLandForm.name.prefixDigit,
        prefixLetter: editLandForm.name.prefixLetter,
        postfixDigit: editLandForm.name.postfixDigit,
        postfixLetter: editLandForm.name.postfixLetter,
      });

      successNotification(
        `Участок <span class="text-bold">${landNumberStringParsing(oldLandName)}</span> успешно переименован в <span class="text-bold">${landNumberStringParsing(editLandForm.name)}</span>`,
        'Номер участка изменён',
        true
      );

      landsBus.emit('lands-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Номер участка не изменён');

      throw error;
    }
  }

  async function checkLandCanBeDeleted(id: string) {
    try {
      const { data } = await http.land.canBeDeletedDetail(id);

      return data;
    } catch (error) {
      errorNotification('Не удалось проверить участок на возможность удаления. Попробуйте позже.', 'Произошла ошибка');

      throw error;
    }
  }

  function onOpenDeleteLandDialog(land: LandDto) {
    useDialog(
      'Вы уверены, что хотите удалить данный участок? После процедуры удаления восстановление будет невозможно.',

      {
        title: 'Удаление участка',
        confirmButtonConfig: {
          title: 'Удалить',
        },
        confirm: async () => deleteLand(land.id, land.name),
      }
    );
  }

  async function deleteLand(landId: string, landName: LandDto['name']) {
    try {
      await http.land.delete(landId);

      successNotification(`Участок <span class="text-bold">${landNumberStringParsing(landName)}</span> успешно удалён`, 'Участок удалён', true);
      landsBus.emit('lands-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось удалить посёлок');
      throw error;
    }
  }

  async function onOpenArchiveLandDialog(land) {
    useDialog(
      (await import('@/components/Lands/ArchiveLandDialog.vue')).default,

      {
        title: 'Архивация участка',
        hideFooter: true,
        confirm: async () => archiveLand(land.id, land.name),
      },
      {}
    );
  }

  async function archiveLand(landId: string, landName: LandDto['name']) {
    try {
      await http.land.archiveDelete(landId);

      successNotification(`Участок <span class="text-bold">${landNumberStringParsing(landName)}</span> успешно архивирован`, 'Участок архивирован', true);
      landsBus.emit('lands-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось архивировать участок');
      throw error;
    }
  }

  async function restoreLand(land: LandDto) {
    try {
      await http.land.restoreCreate(land.id);

      successNotification(`Участок <span class="text-bold">${landNumberStringParsing(land.name)}</span> успешно восстановлен`, 'Участок восстановлен', true);
      landsBus.emit('lands-changed');
    } catch (error) {
      errorNotification('Попробуйте ещё раз позднее', 'Не удалось восстановить участок');
      throw error;
    }
  }

  return {
    onOpenCreateLandsDialog,
    onOpenEditLandDialog,
    onOpenArchiveLandDialog,
    restoreLand,

    checkLandCanBeDeleted,

    onOpenDeleteLandDialog,
  };
}
