import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const devRoutes = [
  {
    name: 'dev',
    path: 'dev',
    component: () => loadImport('../pages/PageDev/PageDev.vue'),
  },
] as const satisfies readonly AppRouteRecord[];
