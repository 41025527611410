import { createApp } from 'vue';
import { createPinia } from 'pinia';

import 'element-plus/dist/index.css';
import './styles/index.scss';

import App from './App.vue';
import router from './router';

import VueTheMask, { tokens } from 'vue-the-mask';
import VueProgressBar from '@aacassandra/vue3-progressbar';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const app = createApp(App);

tokens['D'] = {
  pattern: /[АВЕКМНОРСТУХавекмнорстух]/,
};
app.use(createPinia());
app.use(router);

app.use(VueTheMask);
app.use(VueProgressBar, {
  color: 'var(--primary-400)',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
});

app.mount('#app');
