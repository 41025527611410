<template>
  <div
    v-loading="true"
    class="spinner"
    element-loading-background="var(--neutral-300)" />
</template>

<style lang="scss" scoped>
.spinner {
  width: 100%;
  height: 100%;
  flex: 2;
}
</style>
