import { http } from '@/http';
import { UnwrapNestedRefs } from 'vue';

export * from './validation';
export * from './notifications';

// const deep = (from, ...selectors) =>
//   [...selectors].map((s) =>
//     s
//       .replace(/\[([^\[\]]*)\]/g, '.$1.')
//       .split('.')
//       .filter((t) => t !== '')
//       .reduce((prev, cur) => prev && prev[cur], from)
//   );

// const obj = {
//   selector: { to: { val: 'val to select' } },
//   target: [1, 2, { a: 'test' }],
// };

// deep(obj, 'selector.to.val', 'target[0]', 'target[2].a');

export function numWord(count: number, words: string[]) {
  if (typeof count !== 'number') {
    console.warn('Функция numWord может принимать количество только формата number');
    return;
  }

  if (!Array.isArray(words)) {
    console.warn('Функция numWord может принимать склоняемые слова только формата Array');
    return;
  }

  count = Math.abs(count) % 100;

  const num = count % 10;

  if (count > 10 && count < 20) return words[2];

  if (num > 1 && num < 5) return words[1];

  if (num == 1) return words[0];

  return words[2];
}

export function phoneNumberFormat(phoneNumber: string) {
  if (typeof phoneNumber === 'string') {
    return phoneNumber ? `+7 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9)}` : '-';
  }
  console.warn('Функция phoneNumberFormat может принимать значение только типа string');
}

export function upperCaseFirstLetter(str: string) {
  if (typeof str === 'string') {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }
  console.warn('Функция upperCaseFirstLetter может принимать значение только типа string');
}

export function currencyNumber(number: number | string) {
  if (Number.isNaN(Number(number))) {
    console.warn('Функция currencyNumber может принимать number или string преобразуемый в number');
    return;
  }

  return Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(Number(number));
}

export async function fetchQueue(url: string, customFilter: Record<string, any>) {
  // const filter: Record<string, any> & { pageNumber: number; pageSize: number; orderBy: string; totalPages?: number } = {
  //   pageNumber: 1,
  //   pageSize: 50,
  //   orderBy: 'id desc',
  //   ...customFilter,
  // };
  // const requests = [];
  // try {
  //   const { data, headers } = await http.get(url, {
  //     params: { ...filter },
  //   });
  //   Object.assign(filter, JSON.parse(headers['x-pagination']));
  //   for (filter.pageNumber++; filter.pageNumber <= filter.totalPages; filter.pageNumber++) {
  //     requests.push(
  //       http.get(url, {
  //         params: { ...filter },
  //       })
  //     );
  //   }
  //   const requestsResult = await Promise.all(requests);
  //   return [...data, ...requestsResult.map(({ data }) => data).flat()];
  // } catch (e) {
  //   return Promise.reject(e);
  // }
}

export function landNumberStringParsing(
  landNumber,
  params: { withVillageName?: boolean; emptyText?: string; withNumberSymbol?: boolean } = { withVillageName: false, emptyText: 'б/н', withNumberSymbol: true }
) {
  if (!landNumber) return;

  let result = [];

  const prefixLetter = landNumber.prefixLetter ?? '';
  const prefixDigit = landNumber.prefixDigit ?? '';

  const postfixLetter = landNumber.postfixLetter ?? '';
  const postfixDigit = landNumber.postfixDigit ?? '';

  if (prefixLetter || prefixDigit) {
    result.push(`${prefixLetter}${prefixDigit}`);
  }

  result.push(landNumber.number);

  if (postfixLetter || postfixDigit) {
    result.push(`${postfixLetter}${postfixDigit}`);
  }

  if (result.every((item) => !item)) {
    return params.emptyText;
  }

  return (params.withNumberSymbol ? '№' : '') + result.join('-');
}

export function getObjectFromString<T extends string>(someString: string, format: T) {
  type SplitStringBySpace<T extends string> = T extends `${infer Word} ${infer Rest}` ? Word | SplitStringBySpace<Rest> : T extends `${infer Word}` ? Word : never;
  const splittedString = someString.split(' ');
  const splittedFormat = format.split(' ');

  if (splittedString.length !== splittedFormat.length) {
    throw Error(`Строка не соответсвует формату (${format})`);
  }

  return Object.fromEntries(
    splittedFormat.map((item, index) => {
      return [item, splittedString[index]];
    })
  ) as { [Key in SplitStringBySpace<T>]: string };
}

//@todo Сделать привязку к реальным типам с бека
export function fullNameStringParsing(person: string | { firstName?: string; lastName?: string; middleName?: string }, params: { short: boolean } = { short: true }) {
  if (typeof person === 'string') {
    const splittedName = person.split(' ');

    const lastName = splittedName?.[0] ?? '-';
    const firstName = splittedName?.[1] ?? '-';
    const middleName = splittedName?.[2] ?? '-';

    if (params.short) {
      return `${lastName} ${firstName[0]}. ${middleName[0]}.`;
    } else {
      return `${lastName} ${firstName} ${middleName}`;
    }
  } else if (typeof person === 'object') {
    if (!person.lastName && !person.firstName && !person.middleName) {
      console.warn('Для преобразования ФИО не хватает данных');
      return;
    }

    if (person.lastName && person.middleName) {
      if (params.short) {
        return `${person.lastName} ${person.firstName[0]}. ${person?.middleName[0]}.`;
      } else {
        return `${person.lastName} ${person.firstName} ${person?.middleName}`;
      }
    } else {
      return person.firstName;
    }
  } else {
    console.warn('Функция fullNameStringParsing может принимать только типы string и object формата { firstName: string; lastName: string; middleName: string; ...other}');
    return;
  }
}

export function deepCopy<T extends Record<any, any> | any[]>(item: T): UnwrapNestedRefs<T> {
  return JSON.parse(JSON.stringify(item));
}

// Только число. Включая дробное
export function useInputNumberRef(value) {
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return value;
      },
      set(newValue) {
        newValue = String(newValue).replace(/[^0-9.]/gi, '');

        if (newValue.split('.').length > 2) return;

        value = newValue;

        trigger();
      },
    };
  });
}

// Только целое число
export function useInputIntegerRef(value) {
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return value;
      },
      set(newValue) {
        if (typeof newValue === 'string') {
          const filteredValue = newValue.replace(/[^0-9]/gi, '');

          if (value !== filteredValue) {
            value = filteredValue;
            trigger();
          }
        } else {
          value = newValue;
          trigger();
        }
      },
    };
  });
}

// Только буквы
export function useInputLetterRef(value) {
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return value;
      },
      set(newValue) {
        if (typeof newValue === 'string') {
          const filteredValue = newValue.replace(/[^a-zA-Zа-яёА-ЯЁ]/gi, '');

          if (value !== filteredValue) {
            value = filteredValue;
            trigger();
          }
        } else {
          value = newValue;
          trigger();
        }
      },
    };
  });
}
