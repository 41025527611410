import { AllowedComponentProps, VNodeProps } from 'vue';
import { defineStore } from 'pinia';
import drawersData from '@/components/TheGlobalDrawer/drawers';
import { errorNotification } from '@/helpers';
import { useDebounceFn } from '@vueuse/core';

type DrawersData = typeof drawersData;
type DrawersDataKeys = keyof DrawersData;

type DrawerDataRaw<T extends DrawersDataKeys = DrawersDataKeys> = {
  name: T;
  queryParams: Omit<InstanceType<DrawersData[T]>['$props'], keyof VNodeProps | keyof AllowedComponentProps>;
};

type DrawerDataWithCompoent = {
  name: DrawersDataKeys;
  component: DrawersData[DrawersDataKeys];
  params: Omit<InstanceType<DrawersData[DrawersDataKeys]>['$props'], keyof VNodeProps | keyof AllowedComponentProps>;
};

const DRAWERS_STACK_LS_KEY = 'drawers-stack-data';

export const useDrawerStore = defineStore('drawer', () => {
  const router = useRouter();
  const route = useRoute();

  const isOpen = ref(false);
  const isLoading = ref(false);

  const drawersStack = ref<DrawerDataWithCompoent[]>([]);

  const localDrawersStack = localStorage.getItem(DRAWERS_STACK_LS_KEY);

  if (localDrawersStack) {
    drawersStack.value = JSON.parse(localDrawersStack);
  }

  const currentDrawer = computed<DrawerDataWithCompoent | null>(() => {
    if (drawersStack.value.length) {
      const drawerStackItem = drawersStack.value.at(-1);

      return { ...drawerStackItem, component: drawersData[drawerStackItem.name] };
    }

    return null;
  });

  watch(currentDrawer, (value) => {
    if (value) {
      router.replace({ name: route.name, query: { d: value.name, ...value.queryParams } });
    }
  });

  const debouncedSyncLocalStore = useDebounceFn(() => localStorage.setItem(DRAWERS_STACK_LS_KEY, JSON.stringify(drawersStack.value)), 100);
  watch(drawersStack, debouncedSyncLocalStore, { deep: true });

  function openDrawer<T extends DrawersDataKeys>({ name, queryParams }: DrawerDataRaw<T>) {
    try {
      router.replace({ name: route.name, query: { d: name, ...queryParams } });

      const lastStackItem = drawersStack.value.at(-1);

      const isDuplicate =
        !!lastStackItem &&
        lastStackItem.name === name &&
        Object.entries(lastStackItem.queryParams).length === Object.entries(queryParams).length &&
        Object.entries(lastStackItem.queryParams).every(([key, value]) => queryParams[key] === value);

      if (!isDuplicate) {
        drawersStack.value.push({ name, useFilter: null, queryParams, params: {} });
      }

      isOpen.value = true;
    } catch (error) {
      console.error(error);

      errorNotification(error);
    }
  }

  function closeDrawer(params: { clear?: boolean } = {}) {
    const { clear = false } = params;

    router.replace({ name: route.name });

    isOpen.value = false;
    isLoading.value = false;

    if (clear) {
      drawersStack.value = [];
    }
  }

  function goBack() {
    if (!isLoading.value) {
      drawersStack.value.splice(-1, 1);
    }
  }

  async function initDrawer(callback: (sync: () => any) => MaybePromiseLike<any>, params = {}) {
    try {
      isLoading.value = true;
      await callback();
    } catch (e) {
      console.error(e);
      errorNotification(e);
    } finally {
      isLoading.value = false;
    }
  }

  function syncFilters({ filter, pagination }) {
    console.log('syncFilters', filter, pagination);

    const drawerStackItem = drawersStack.value.at(-1);

    drawerStackItem.useFilter = {
      filter,
      pagination,
    };
  }

  function applyFilters({ changeRawFilterMany, changePagination }) {
    if (currentDrawer.value.useFilter) {
      console.log('applyFilters', currentDrawer.value.useFilter);
      changeRawFilterMany?.(currentDrawer.value.useFilter.filter);
      changePagination?.({ pageNumber: currentDrawer.value.useFilter.pagination.currentPage });
    }
  }

  return {
    isOpen: readonly(isOpen),
    isLoading: readonly(isLoading),
    currentDrawer,
    openDrawer,
    closeDrawer,
    goBack,
    drawersStack,
    initDrawer,
    applyFilters,
    syncFilters,
  };
});
