import { loadImport } from '../helpers';
import { AppRouteRecord } from '../types';

export const startRoutes = [
  {
    name: 'start',
    path: '',
    redirect: { name: 'villages' },
    meta: {
      requiresAuth: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
