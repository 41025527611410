<template>
  <div class="page-404">
    <div
      class="flex gap-2 items-center"
      style="position: absolute; top: 48px; left: 48px">
      <img
        src="/logo/logo-img.png"
        width="38"
        height="38" />

      <div class="logo-text" />
    </div>

    <div style="font-size: 300px; font-weight: 700; line-height: 363.07px; color: rgba(255, 255, 255, 0.06)">404</div>

    <div
      class="flex flex-col items-center gap-4"
      style="margin-bottom: 64px">
      <div
        class="text-H3"
        style="color: var(--neutral-100)">
        Страницы не существует
      </div>
      <div
        class="text-T1"
        style="color: var(--neutral-500)">
        Или что-то пошло не так...
      </div>
    </div>

    <div class="flex gap-3">
      <AppButton
        v-if="isRedirected"
        type="outline"
        icon-left="icons/arrow">
        Вернуться назад
      </AppButton>

      <AppButton
        type="primary"
        icon-right="icons/arrow"
        icon-right-rotate="180deg"
        @click="$router.push({ name: 'villages' })">
        Перейти на главную
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute();

const isRedirected = ref(false);

onMounted(() => {
  if (route.redirectedFrom) {
    isRedirected.value = true;
  }
});
</script>

<style lang="scss" scoped>
.page-404 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(0deg, rgba(6, 43, 34, 1), rgba(13, 78, 63, 1));
}

.logo-text {
  width: 68px;
  height: 34px;
  mask-image: url('/logo/logo-text-full-ru.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('/logo/logo-text-full-ru.svg');
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;

  background-color: var(--neutral-100);
}
</style>
