import { defineStore } from 'pinia';
import { ComponentInternalInstance } from 'vue';
import axios from 'axios';
import RedirectToCreatePersonDialog from '@/components/LandOwnerships/RedirectToCreatePersonDialog.vue';

export const useGlobalStore = defineStore('global', () => {
  const appVersion = ref(null);
  const appBundleVersion = ref(null);

  async function initAppVersion() {
    if (import.meta.env.DEV) return;

    const { Version, BundleVersion } = await getVersion();

    appBundleVersion.value = BundleVersion;
    appVersion.value = Version;

    console.log('🔹 App Version:', appVersion.value);
    // console.log('appBundleVersion', appBundleVersion.value);
  }

  async function getVersion() {
    const { data } = await axios.get('/app-bundle-version-info.json', { baseURL: '/' });

    return data;
  }

  const userLocalSettings = ref(null);
  const settings = localStorage.getItem('user-local-settings');

  if (settings) {
    userLocalSettings.value = JSON.parse(settings);
  } else {
    userLocalSettings.value = {
      messageRedirectToCreatePersonForm: false,
    };

    localStorage.setItem('user-local-settings', JSON.stringify(userLocalSettings.value));
  }

  function setUserSettings({ key, value }) {
    userLocalSettings.value[key] = value;
    localStorage.setItem('user-local-settings', JSON.stringify(userLocalSettings.value));
  }

  const topProgress: Ref<ComponentInternalInstance> = ref(null);
  const isMainContentLoading = ref(false);

  function topProgressStart() {
    topProgress.value.appContext.config.globalProperties.$Progress.start();
  }

  function topProgressFinish() {
    topProgress.value.appContext.config.globalProperties.$Progress.finish();
  }

  async function handleMainContentLoading(handler: () => any) {
    isMainContentLoading.value = true;
    await handler();
    isMainContentLoading.value = false;
  }

  const APP_RUN_TESTING = ref(false);
  function setAppTesting(value) {
    APP_RUN_TESTING.value = value;
  }

  return {
    appVersion,
    appBundleVersion,
    initAppVersion,
    getVersion,

    userLocalSettings,
    setUserSettings,

    isMainContentLoading,
    topProgress,
    topProgressStart,
    topProgressFinish,
    handleMainContentLoading,

    APP_RUN_TESTING,
    setAppTesting,
  };
});
